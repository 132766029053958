<template>
    <div id="content" class="ebiz">
        <!-- cont_body// -->
        <section class="cont_body">

            <!-- sub_banner// -->
            <div class="sub_banner">
                <div class="inner">
                    <div class="txt_area">
                        <p class="small_txt">Focus on your work!</p>
                        <p class="main_txt">디지털 비즈니스의 기술적 문제 해결에 엔더스가 함께합니다.  </p>
                    </div>
                    <div class="bnnr_img">
                        <img src="@/assets/img/sub/ebiz/bannr_bg.png" alt="ebiz서비스">
                    </div>
                </div>
            </div>
            <!-- //sub_banner -->
            <h2 class="h2_sub inner">
                <span class="gray_txt">환경은 변화하는데 디지털 비즈니스는 제자리인가요?</span>
                업무 효율 증대와 빠른 비즈니스 성장을 위한<br>
                최적의 E-BIZ 서비스를 제공합니다.
            </h2>
            <!-- work_prc// -->
            <div class="work_prc">
                <div class="inner">
                    <h3>
                        <strong>Work Process</strong>
                        <span>단 하나의 과정도 전문적인 노하우와 방법론을 통해 차원이 다른 서비스를 제공합니다.</span>
                    </h3>
                    <div class="work_stepwrap">
                        <ol>
                            <li>
                                <div class="icon_circle"><img src="@/assets/img/sub/ebiz/workprc_ebiz_01.png" alt="요구사항 분석"></div>
                                <div class="work_desc">
                                    <strong>
                                        <span class="txt_step">STEP 01</span>
                                        요구사항 분석
                                    </strong>
                                    <p>
                                        요구사항 및 개발환경 분석
                                    </p>
                                </div>
                            </li>
                            <li>
                                <div class="icon_circle"><img src="@/assets/img/sub/ebiz/workprc_ebiz_02.png" alt="전략 수집"></div>
                                <div class="work_desc">
                                    <strong>
                                        <span class="txt_step">STEP 02</span>
                                        전략 수집
                                    </strong>
                                    <p>
                                        다양한 분석 도구를 활용한<br>
                                        문제점 진단 및 전략 수립
                                    </p>
                                </div>
                            </li>
                            <li>
                                <div class="icon_circle"><img src="@/assets/img/sub/ebiz/workprc_ebiz_03.png" alt="설계 및 개발"></div>
                                <div class="work_desc">
                                    <strong>
                                        <span class="txt_step">STEP 03</span>
                                        설계 및 개발
                                    </strong>
                                    <p>
                                        아키텍쳐 설계<br>
                                        개발 환경 구축 및 실행
                                    </p>
                                </div>
                            </li>
                            <li>
                                <div class="icon_circle"><img src="@/assets/img/sub/ebiz/workprc_ebiz_04.png" alt="모니터링 관리      "></div>
                                <div class="work_desc">
                                    <strong>
                                        <span class="txt_step">STEP 04</span>
                                        모니터링 관리
                                    </strong>
                                    <p>
                                        지속적 모니터링을<br>
                                        통한 안정화
                                    </p>
                                </div>
                            </li>

                        </ol>
                    </div>
                </div>
            </div>
            <!-- //work_prc -->

            <!-- service_area// -->
            <ServiceInfo />
            <!-- service_area// -->

            <!-- gray_box// -->
            <div class="gray_box">
                <div class="inner">
                    <h3>
                        E-BIZ 서비스가 선사하는 최상의 가치
                    </h3>
                    <div class="support_list">
                        <dl>
                            <dt>비즈니스 통찰력 향상</dt>
                            <dd>
                                실시간 정보 반영 및<br>
                                일관성 있는 인프라 제공
                            </dd>
                        </dl>
                        <dl>
                            <dt>운영 비용 절감</dt>
                            <dd>
                                간소화된 비즈니스 프로세스<br>
                                및 모범 사례 제공
                            </dd>
                        </dl>
                        <dl>
                            <dt>향상된 협업</dt>
                            <dd>
                                데이터 공유 및 유연한 의사결정<br>
                                을 위한 협업 프로세스 제공
                            </dd>
                        </dl>
                    </div>
                </div>
            </div>
            <!-- //gray_box -->
          <!-- sub_common// -->
            <PortfolioBanner />
          <!-- //sub_common -->
          <!-- 문의상담영역// -->
            <ConsultBanner />

          <!-- //문의상담영역 -->
        </section>
        <!-- //cont_body -->
    </div>
</template>
<script>
import ConsultBanner from '@/components/ConsultBanner.vue';
import PortfolioBanner from '@/components/PortfolioBanner.vue';
import ServiceInfo from './ebiz-compo/ServiceInfo.vue';

export default {
  // name은 파일 이름을 쓰시면 됩니다. ex) Portfolio.vue => Portfolio
  name: 'Ebiz',
  // components 위에서 임포트한 컴포넌트를 여기에 등록하고 위에 HTML영역에 태그로 사용가능합니다.
  components: {
    ConsultBanner,
    PortfolioBanner,
    ServiceInfo,
  },
};
</script>
