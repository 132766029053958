<template>
  <div class="graybox">
    <p class="required">*필수기재 항목</p>
    <div class="list-area">
      <ul>
        <li class="col-full">
          <label class="required">년월</label>
          <div class="list-item">
            <div class="datepicker">
              <input type="date" id="start" name="datepicker" v-model="updateInfo.history_date">
            </div>
          </div>
        </li>
        <li class="col-full" style="border-top:1px solid #E1E1E1;">
          <label>내용</label>
          <div class="list-item">
            <input type="text" placeholder="70자 이내로 작성해주세요." maxlength="70" v-model="updateInfo.content">
          </div>
        </li>
        <li class="col-full">
          <label>등록 일시</label>
          <div class="list-item">
            <p class="inline-txt">{{changeDateTime(detail.reg_date)}}</p>
          </div>
        </li>
        <li class="col-full">
          <label>수정 일시</label>
          <div class="list-item">
            <p class="inline-txt">{{changeDateTime(detail.update_date)}}</p>
          </div>
        </li>
      </ul>
    </div>
    <ListBtn />
    <div class="btn-wrap">
      <button type="button" class="btn big" @click="deleteHistory()">삭제</button>
      <button type="button" class="btn big fullblue" @click="updateHistory(updateInfo)">저장</button>
    </div>
  </div>
</template>

<script>
import ListBtn from '@/components/admin/ListBtn.vue';
import moment from 'moment'
import historyApi from "@/apis/history";
import { datetimeToString } from "@/util/datetime";

export default {
  name: 'EditContent',
  components: {
		ListBtn,
  },
  mounted() {
    this.init();
  },
  data(){
    return{
      detail: {},
      updateInfo:{
        history_date: moment().format('YYYY-MM-DD'),
        content: '',
      }
    }
  },
  watch:{
    detail(){
      this.updateInfo.history_date = this.detail.history_date;
      this.updateInfo.content = this.detail.content;
    }
  },
  methods: {
    async init() {
      const result = await historyApi.getHistoryDetail(this.$route.params.id);
      if (result.code === 500) {
        await this.$alert("데이터 송신 중 오류가 발생했습니다.", "", "error");
        this.$router.push("/admin/history");
        return;
      }
      this.detail = result.result;
    },
    async deleteHistory() {
      this.$confirm("삭제된 정보는 복구가 불가합니다. 정말 삭제하겠습니까?", "", "warning")
        .then(async () => {
          const result = await historyApi.deleteHistoryDetail(this.$route.params.id);
          if (result.code === 500) {
            await this.$alert("데이터 삭제 중 오류가 발생했습니다.", "", "error");
            return;
          }
          await this.$alert("삭제 되었습니다.", "", "success");
          this.$router.push("/admin/history");
        })
        .catch(() => {});
    },
    async updateHistory(data) {
      this.$confirm("입력 정보를 업데이트 하시겠습니까?", "", "question")
        .then(async () => {
          const result = await historyApi.updateHistoryDetail(this.$route.params.id, data);
          console.log(result);
          if (result.code === 500) {
            await this.$alert("데이터 업데이트 중 오류가 발생했습니다.", "", "error");
            return;
          }
          await this.$alert("업데이트 되었습니다.", "", "success");
          this.$router.push("/admin/history");
        })
        .catch(() => {});
    },
    changeDateTime(date) {
      if (date) {
        const parsingData = new Date(date);
        return datetimeToString(parsingData);
      }
      return "";
    }
  }
};
</script>
