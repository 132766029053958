<template>
    <div id="content" class="about">
        <!-- cont_body// -->
        <section class="cont_body">

            <!-- sub_banner// -->
            <div class="sub_banner">
                <div class="inner">
                    <div class="txt_area">
                        <p class="small_txt">We are IT Enders!</p>
                        <p class="main_txt">비즈니스 성공을 열어줄 성실하고 든든한 파트너가 되겠습니다.</p>
                    </div>
                    <div class="bnnr_img">
                        <img src="@/assets/img/sub/about/bannr_bg.png" alt="엔더스 소개">
                    </div>
                </div>
            </div>
            <!-- //sub_banner -->

            <h2 class="h2_sub inner">
                <span class="gray_txt">Enders Cloud & E-BIZ Technology will shine your business</span>
                엔더스는 클라우드 특화 E-BIZ 서비스 개발 운영 기업입니다
            </h2>
            <!-- subtitle_area// -->
            <div class="subtitle_area">
                <h3 class="h3_sub">
                    핵심가치
                    <span class="gray_txt">엔더스의 핵심가치는 더 나은 미래를 향해 나아갈 행동 양식입니다.</span>
                </h3>
                <div class="dv_line"></div>
            </div>
            <!-- //subtitle_area -->

            <!-- visual_content// -->
            <div class="visual_content">
                <div class="inner">
                    <div>
                        <div class="txt_cont">
                            <h3 class="cont_ttl">
                                <span class="col_red">올바른 방향성</span>
                                <strong>
                                    <span class="mb_block">사람이 중심이 되는</span>
                                    미래 경영
                                </strong>
                            </h3>
                            <div class="dv_line"></div>
                            <p>
                                <span class="mb_block">사람 중심, 사용자 중심의 기업,</span>
                                <span class="mb_block"> 기술이 아닌 사람 중심의 변화를 이끌어 내어</span>
                                사람이 빛나는 디지털 미래를 추구합니다.
                            </p>
                        </div>
                        <div class="img_cont"><img src="@/assets/img/sub/about/about_visual_01.png" alt="기업경영"></div>
                    </div>

                    <div>

                        <div class="txt_cont">
                            <h3 class="cont_ttl">
                                <span class="col_red">독보적 기술력</span>
                                <strong>
                                    <span class="mb_block">고객가치를 높이기 위한</span>
                                    기술개발
                                </strong>
                            </h3>
                            <div class="dv_line"></div>
                            <p>
                                <span class="mb_block">만족 이상의 감동을 전하는 기술개발을 위해 </span>
                                <span class="mb_block">엔더스는 끊임 없이 나아갑니다.</span>
                                엔더스의 기술력이 품격 있는 가치를 만듭니다.
                            </p>
                        </div>
                        <div class="img_cont" style="text-align:left;"><img src="@/assets/img/sub/about/about_visual_02.png" alt="기술개발"></div>
                    </div>

                    <div>
                        <div class="txt_cont">
                            <h3 class="cont_ttl">
                                <span class="col_red">차별적 강점</span>
                                <strong>
                                    <span class="mb_block">도전적 실천으로</span>
                                    지속적인 혁신을 실행
                                </strong>
                            </h3>
                            <div class="dv_line"></div>
                            <p>
                                <span class="mb_block">변화를 두려워 하지 않습니다. </span>
                                <span class="mb_block">엔더스의 일상은 도전의 연속이며 </span>
                                <span class="mb_block">가치를 창조하고 혁신을 이끌 주역을 목표로</span>
                                강력한 엔진 기업을 향해 나아갑니다.
                            </p>
                        </div>
                        <div class="img_cont"><img src="@/assets/img/sub/about/about_visual_03.png" alt="도전정신"></div>
                    </div>
                </div>
            </div>
            <!-- //visual_content -->

            <!-- service_area about// -->
            <ServiceInfo />
            <!-- service_area about// -->

            <!-- history_area// -->
            <HistorySwiper />
            <!-- //history_area -->

            <!-- 문의상담영역// -->
            <ConsultBanner />
            <!-- //문의상담영역 -->
        </section>
        <!-- //cont_body -->

        <!-- top button//  -->
        <button class="btn_top"><span class="hidden">TOP</span></button>
        <!-- //top button -->
    </div>
</template>

<script>
import ConsultBanner from '@/components/ConsultBanner.vue';
import ServiceInfo from './compo/ServiceInfo.vue';
import HistorySwiper from './compo/HistorySwiper.vue';

export default {
  // name은 파일 이름을 쓰시면 됩니다. ex) Portfolio.vue => Portfolio
  name: 'About',
  // components 위에서 임포트한 컴포넌트를 여기에 등록하고 위에 HTML영역에 태그로 사용가능합니다.
  components: {
    HistorySwiper,
    ServiceInfo,
    ConsultBanner,
  },
  methods: {
    movePage(path) {
      if (this.$route.path !== path) {
        this.$router.push(path);
      }
    },
  },
};
</script>
