<template>
  <!-- content// -->
  <div id="content" class="location">
    <!-- cont_body// -->
    <section class="cont_body">
      <!-- inner// -->
      <div class="inner">
        <!-- 대제목 영역// -->
        <PageTitle title="Location" subTitle="엔더스 크루와 만나는 곳" />
        <!-- //대제목 영역 -->
        <!-- 지도 영역// -->
        <div class="loca_wrap">
          <div class="map_box"></div>
          <div class="info_loca">
            <h3>오시는 길</h3>
            <dl>
              <dt>ADD</dt>
              <dd>
                <p>(06178)</p>
                <p>서울특별시 강남구 테헤란로 84길 14</p>
                <p>지어로빌딩 11층 ㈜엔더스</p>
              </dd>
            </dl>
            <dl>
              <dt>TEL</dt>
              <dd>02-6082-9630</dd>
            </dl>
            <dl>
              <dt>EMAIL</dt>
              <dd>support@enders.co.kr</dd>
            </dl>
          </div>
          <!-- 0803 네이버지도버튼// -->
          <a href="http://naver.me/x9J0xdSM" target="_blank" class="link_map">
            네이버 지도<span
              >더보기화살표
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 6 10.5"
              >
                <defs>
                  <clipPath id="clip-path">
                    <rect id="box" width="6" height="10.5" fill="#ff6d6d" opacity="0.47" />
                  </clipPath>
                </defs>
                <g id="ic__" data-name="ic_&gt;" clip-path="url(#clip-path)">
                  <path
                    id="arrow-ios-forward-fill"
                    d="M14.135,16.387a.637.637,0,0,1-.49-1.044L16.5,11.931l-2.75-3.419a.651.651,0,1,1,1.025-.8l3.075,3.82a.637.637,0,0,1,0,.808l-3.183,3.82a.637.637,0,0,1-.528.229Z"
                    transform="translate(-12.748 -6.706)"
                    fill="#777"
                  />
                </g>
              </svg>
            </span>
          </a>
          <!-- //0803 네이버지도버튼 -->
        </div>
        <!-- //지도 영역 -->
        <!-- 문의상담영역// -->
        <ConsultBanner />
        <!-- //문의상담영역 -->
      </div>
      <!-- //inner -->
    </section>
    <!-- //cont_body -->
  </div>
</template>

<script>
import PageTitle from "@/components/PageTitle.vue";
import ConsultBanner from "@/components/ConsultBanner.vue";

export default {
  // name은 파일 이름을 쓰시면 됩니다. ex) Portfolio.vue => Portfolio
  name: "Location",
  // components 위에서 임포트한 컴포넌트를 여기에 등록하고 위에 HTML영역에 태그로 사용가능합니다.
  components: {
    PageTitle,
    ConsultBanner
  }
};
</script>
