<template>
  <div class="service_area">
    <div class="inner">
      <h3 class="h3_ttl">Service</h3>
      <!-- service_list// -->
      <div class="service_list type_pc">
        <SeviceInfoItem v-for="(item, idx) in serviceItem" :key="idx" :pcItem="item"/>
      </div>
      <!-- //type_pc -->

      <!-- service_list// -->
      <div class="service_list type_mb">
        <Swiper :options="swiperOptions">
          <SwiperSlide class="swiper-slide" v-for="(item, idx) in serviceItem" :key="idx" >
            <SeviceInfoItem :pcItem="item"/>
          </SwiperSlide>
          <div class="swiper-pagination mb_srv_pager swiper-pagination-bullets" slot="pagination"></div>
        </Swiper>
      </div>
      <!-- //type_mb-->
      <!-- //service_list -->
    </div>
  </div>
</template>

<script>
import SeviceInfoItem from './SeviceInfoItem.vue';

export default {
  name: 'ServiceInfo',
  components: {
    SeviceInfoItem,
  },
  data() {
    return {
      swiperOptions: {
        spaceBetween: 30,
        pagination: {
          el: '.swiper-pagination',
        },
      },
      serviceItem: [
        {
          img: require('@/assets/img/sub/ebiz/icon_ebizservice_01.png'),
          title: '비즈니스 통합 운영서비스',
          content:
            '디지털 비즈니스를 위한 심층 분석부터 환경 구축, 설계, 운영 관리까지 최적의 맞춤형 통합 운영서비스를 제공합니다.',
        },
        {
          img: require('@/assets/img/sub/ebiz/icon_ebizservice_02.png'),
          title: '비즈니스 플랫폼 컨설팅',
          content:
            '디지털 비즈니스의 시작, 종합적인 관점으로 비즈니스 플랫폼 컨설팅을 통해 핵심 가치를 만들어드립니다.',
        },
        {
          img: require('@/assets/img/sub/ebiz/icon_ebizservice_03.png'),
          title: 'Web & Mobile app 제작',
          content:
            '엔더스는 모바일 앱, 반응형 웹사이트, 모바일 웹사이트 등 다양한 제작 경험을 기반으로 최적의 서비스 채널을 구축합니다.',
        },
        {
          img: require('@/assets/img/sub/ebiz/icon_ebizservice_04.png'),
          title: 'Business Intelligence',
          content:
            '데이터 기반의 효율적인 의사 결정 지원을 위해 다양한 비즈니스 분석, 고급 통계, 맞춤형 분석환경 등의 최적의 분석 사례를 엔더스 전문가가 제공해드립니다.',
        },
      ],
    };
  },
};
</script>
