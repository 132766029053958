<template>
  <div id="layer_recruit" class="layerpop layer_recruit open">
    <!-- id값 수정 가능 -->
    <div class="inner">
      <div class="visual_recruit clear">
        <h2 class="layer_title">
          <span>2022 ENDERS</span>
          <strong>
            신입사원<br />
            공개채용
          </strong>
        </h2>
        <div class="bg_recruit">
          <img src="@/assets/img/main/visual_crew.png" alt="신입사원공개채용" />
        </div>
      </div>
      <div class="layercont">
        <div class="cont">
          <form>
            <fieldset>
              <legend>신입사원 공개채용</legend>
              <dl>
                <dt>지원기간</dt>
                <dd class="date">
                  <strong>6.20</strong>
                  월
                  <span>~</span>
                  <strong>7.20</strong>
                  수
                </dd>
              </dl>
              <p class="desc">
                지원분야별 상세요강과 지원방법은 채용사이트의 채용공고를 확인해주세요.
              </p>

              <!-- btn_area// -->
              <div class="btn_area">
                <a href="https://www.jobkorea.co.kr/company/42092286/Recruit?GI_Part_Code=&Search_Order=1&ChkDispType=1&Part_Btn_Stat=0" target="_blank" class="link_iqr">
                  <img src="@/assets/img/common/logo_jobkorea.svg" alt="잡코리아 로고" />
                  채용공고 보기
                  <span>
                    더보기화살표
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      viewBox="0 0 6 10.5"
                    >
                      <defs>
                        <clipPath id="clip-path">
                          <rect
                            id="box"
                            width="6"
                            height="10.5"
                            fill="#ff6d6d"
                            opacity="0.47"
                          ></rect>
                        </clipPath>
                      </defs>
                      <g id="ic__" data-name="ic_>" clip-path="url(#clip-path)">
                        <path
                          id="arrow-ios-forward-fill"
                          d="M14.135,16.387a.637.637,0,0,1-.49-1.044L16.5,11.931l-2.75-3.419a.651.651,0,1,1,1.025-.8l3.075,3.82a.637.637,0,0,1,0,.808l-3.183,3.82a.637.637,0,0,1-.528.229Z"
                          transform="translate(-12.748 -6.706)"
                          fill="#777"
                        ></path>
                      </g>
                    </svg>
                  </span>
                </a>
                <a href="https://www.saramin.co.kr/zf_user/company-info/view-inner-recruit?csn=T2hpbGJlTzFWVy8xTkM5ZUMrUU9pdz09" target="_blank" class="link_iqr">
                  <img src="@/assets/img/common/logo_saramin.svg" alt="사람인 로고" />
                  채용공고 보기
                  <span>
                    더보기화살표
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      viewBox="0 0 6 10.5"
                    >
                      <defs>
                        <clipPath id="clip-path">
                          <rect
                            id="box"
                            width="6"
                            height="10.5"
                            fill="#ff6d6d"
                            opacity="0.47"
                          ></rect>
                        </clipPath>
                      </defs>
                      <g id="ic__" data-name="ic_>" clip-path="url(#clip-path)">
                        <path
                          id="arrow-ios-forward-fill"
                          d="M14.135,16.387a.637.637,0,0,1-.49-1.044L16.5,11.931l-2.75-3.419a.651.651,0,1,1,1.025-.8l3.075,3.82a.637.637,0,0,1,0,.808l-3.183,3.82a.637.637,0,0,1-.528.229Z"
                          transform="translate(-12.748 -6.706)"
                          fill="#777"
                        ></path>
                      </g>
                    </svg>
                  </span>
                </a>
              </div>
              <!-- //btn-area -->

              <p class="caution">※ 지원기간은 상황에 따라 조기 종료될 수 있습니다.</p>
            </fieldset>
          </form>
        </div>
      </div>
      <div class="chk_area">
        <input type="checkbox" id="apply_chk" v-model="disableCheck" />
        <label for="apply_chk">오늘하루 보지 않기</label>
      </div>
      <button type="button" class="btn_layerclose" @click="closePopup"><span>닫기</span></button>
    </div>
    <span class="layer_bg"></span>
  </div>
</template>

<script>
export default {
  name: 'RecruitPopup',
  data(){
    return {
      disableCheck: false
    }
  },
  methods: {
    closePopup(){
      if(this.disableCheck){
        const tomorrow = new Date(new Date().setDate(new Date().getDate() + 1));
        this.$cookies.set("popup-disable", "true", new Date(tomorrow.getFullYear(), tomorrow.getMonth(), tomorrow.getDate(), 0, 0).toUTCString());
      }
      this.$emit('close-popup')
    }
  },
};
</script>

<style></style>
