import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userStatus: '',
    asyncLoading: false,
  },
  mutations: {
    login(state, payload) {
      state.userStatus = payload;
    },
    logout(state){
      state.userStatus = ''
    },
    asyncLoading(state, flag) {
      state.asyncLoading = flag;
    },
  },
  actions: {
    login({ commit }, payload) {
      commit('login', payload);
    },
    logout({ commit }) {
      commit('logout');
    },
    showSpinner({ commit }) {
      commit('asyncLoading', true);
    },
    hideSpinner({ commit }) {
      commit('asyncLoading', false);
    },
  },
  getters: {
    userStatus: (state) => state.userStatus,
    asyncLoading: (state) => state.asyncLoading,
  },
  modules: {
  },
});
