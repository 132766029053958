<template>
  <div class="btn-wrap tar">
    <button class="btn fullgray" @click="$router.back()">목록</button>
  </div>
</template>

<script>
export default {
  name: 'ListBtn',
};
</script>

<style></style>
