function toNumberFormatFilledWithZero(val, size) {
  return val.toString().padStart(size, "0");
}

export function datetimeToString(val) {
  const year = val.getFullYear();
  const month = toNumberFormatFilledWithZero(val.getMonth() + 1, 2);
  const date = toNumberFormatFilledWithZero(val.getDate(), 2);
  const hour = toNumberFormatFilledWithZero(val.getHours(), 2);
  const minutes = toNumberFormatFilledWithZero(val.getMinutes(), 2);
  const seconds = toNumberFormatFilledWithZero(val.getSeconds(), 2);
  return `${year}-${month}-${date} ${hour}:${minutes}:${seconds}`;
}

export function dateToString(val) {
  const year = val.getFullYear();
  const month = toNumberFormatFilledWithZero(val.getMonth() + 1, 2);
  const date = toNumberFormatFilledWithZero(val.getDate(), 2);
  return `${year}-${month}-${date}`;
}

export function stringToDatetime(val) {
  return new Date(val);
}
