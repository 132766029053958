<template>
  <div class="graybox">
    <p class="required">*필수기재 항목</p>
    <div class="list-area">
      <ul>
        <li class="col-full">
          <label class="required">년월</label>
          <div class="list-item">
            <div class="datepicker">
              <input type="date" id="start" name="datepicker" v-model="history.history_date">
            </div>
          </div>
        </li>
        <li class="col-full" style="border-top:1px solid #E1E1E1;">
          <label>내용</label>
          <div class="list-item">
            <input type="text" placeholder="70자 이내로 작성해주세요." maxlength="70" v-model="history.content">
          </div>
        </li>
      </ul>
    </div>
    <div class="btn-wrap">
      <button type="button" class="btn big" @click="$router.back()">취소</button>
      <button type="button" class="btn big fullblue" @click="createHistory(history)">등록</button>
    </div>
  </div>
</template>

<script>
import historyApi from "@/apis/history";
import moment from "moment"

export default {
  name: 'EnrollContent',
	data() {
		return {
      history: {
        history_date: moment().format('YYYY-MM-DD'),
        content: '',
      }
		}
	},
	methods: {
		async createHistory(history) {
      this.$confirm("입력 정보를 등록 하시겠습니까?", "", "question")
        .then(async () => {
          const result = await historyApi.createHistoryDetail(history);
          if (result.code === 500) {
            await this.$alert("데이터 등록 중 오류가 발생했습니다.", "", "error");
            return;
          }
          await this.$alert("등록 되었습니다.", "", "success");
          this.$router.push("/admin/history");
        })
        .catch(() => {});
    }
	}
};
</script>

<style></style>
