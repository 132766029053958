<template>
  <div class="service_area">
    <div class="inner">
      <h3 class="h3_ttl">클라우드 트랜스포메이션</h3>
      <!-- service_list// -->
      <div class="service_list type_pc">
        <SeviceInfoItem v-for="(item, idx) in serviceItem" :key="idx" :pcItem="item"/>
      </div>
      <!-- //type_pc -->

      <!-- service_list// -->
      <div class="service_list type_mb">
        <Swiper :options="swiperOptions">
          <SwiperSlide class="swiper-slide" v-for="(item, idx) in serviceItem" :key="idx" >
            <SeviceInfoItem :pcItem="item"/>
          </SwiperSlide>
          <div class="swiper-pagination mb_srv_pager swiper-pagination-bullets" slot="pagination"></div>
        </Swiper>
      </div>
      <!-- //type_mb-->
      <!-- //service_list -->
    </div>
  </div>
</template>

<script>
import SeviceInfoItem from './SeviceInfoItem.vue';

export default {
  name: 'ServiceInfo',
  components: {
    SeviceInfoItem,
  },
  data() {
    return {
      swiperOptions: {
        spaceBetween: 30,
        pagination: {
          el: '.swiper-pagination',
        },
      },
      serviceItem: [
        {
          img: require('@/assets/img/sub/cloud/icon_cloudtrans_01.png'),
          title: '클라우드 컨설팅',
          content:
            '기업의 비즈니스만 전력하도록 맞춤 클라우드 전략 및 프로세스 수립을 제공합니다.',
        },
        {
          img: require('@/assets/img/sub/cloud/icon_cloudtrans_02.png'),
          title: 'DevOps 환경구성',
          content:
            '클라우드를 이용한 DevOps 환경 구성을 통해 보다 나은 협업 문화와 개발 환경을 제공합니다.',
        },
        {
          img: require('@/assets/img/sub/cloud/icon_cloudtrans_03.png'),
          title: '빅데이터 클라우드',
          content:
            '클라우드 기반의 빅데이터 아키텍쳐 구축과 플랫폼 운영, 분석 등 다양한 서비스를 제공합니다.',
        },
        {
          img: require('@/assets/img/sub/cloud/icon_cloudtrans_04.png'),
          title: '클라우드 마이그레이션',
          content:
            '기업 혁신과 가치창출을 위한 최적의 클라우드 마이그레이션 전략을 지원합니다.',
        },
        {
          img: require('@/assets/img/sub/cloud/icon_cloudtrans_05.png'),
          title: '데이터 마이그레이션',
          content:
            '기업 환경에 맞는 DB 설계 및 최적화된 클라우드로 안정적인 데이터 이전을 제공합니다.',
        },
      ],
    };
  },
};
</script>
