<template>
  <div id="content" class="solution">
    <!-- cont_body// -->
    <section class="cont_body">
      <!-- sub_banner// -->
      <div class="sub_banner">
        <!-- ums배너// -->
        <div class="sub_ums active" v-if="activeClass.ums && false">
          <div class="inner">
            <div class="txt_area">
              <p class="small_txt">AXon UMS!</p>
              <p class="main_txt">
                비즈니스 커뮤니케이션에 최적화된 멀티 채널 통합 메시징 서비스가
                필요하다면
              </p>
            </div>
            <div class="bnnr_img">
              <img
                src="@/assets/img/sub/axon/ums_bannr_bg.png"
                alt="UMS 서비스"
              />
            </div>
          </div>
        </div>
        <!-- //ums배너 -->
        <!-- eis배너// -->
        <div class="sub_eis active" v-if="activeClass.eis && false">
          <div class="inner">
            <div class="txt_area">
              <p class="small_txt">AXon EIS!</p>
              <p class="main_txt">
                신속한 의사결정을 위한 데이터 기반 경영이 필요하다면
              </p>
            </div>
            <div class="bnnr_img">
              <img
                src="@/assets/img/sub/axon/eis_bannr_bg.png"
                alt="EIS 서비스"
              />
            </div>
          </div>
        </div>
        <!-- //eis배너 -->
        <!-- erp배너// -->
        <div class="sub_erp active" v-if="activeClass.erp">
          <div class="inner">
            <div class="txt_area">
              <p class="small_txt">Ezen Cloud</p>
              <p class="main_txt">
                네트워크 마케팅 비즈니스를 위한
                완벽한 플랫폼을 찾고 계십니까?
              </p>
              <span>MLM 소프트웨어 개발 전문 기업으로 다양한 마케팅 회사를 성공으로 안내했습니다.
              앞으로 귀사의 비즈니스가 번창할 수 있도록 혁신적인 플랫폼을 제공하기 위해<br>
              최선을 다하겠습니다.</span>
            </div>
            <div class="bnnr_img">
              <img
                src="@/assets/img/sub/axon/erp_bannr_bg.png"
                alt="ERP 서비스"
              />
            </div>
          </div>
        </div>
        <!-- //erp배너 -->
        
      </div>
      <!-- //sub_banner -->

      <!-- solution_wrap// -->
      <div class="solution_wrap">
        <!-- 탭 클릭 시 각 탭의 글자색이 바뀌고 각 sub_banner > div와 하위 tab content가 바뀐다 -->
        <ul class="tab_list solution_tabs">
          <li :class="activeClass.ums" v-if="false">
            <a href="#" @click.prevent="activeTabBtn('ums')">&nbsp;&nbsp;AXon UMS</a>
          </li>
          <li :class="activeClass.eis" v-if="false">
            <a href="#" @click.prevent="activeTabBtn('eis')">AXon EIS</a>
          </li>
          <li :class="activeClass.erp">
            <a href="#" @click.prevent="activeTabBtn('erp')"></a>
          </li>
        </ul>

        <!-- ums tab_content// -->
        <div class="tab_content ums active" v-if="activeClass.ums">
          <h2 class="h2_sub inner">
            <span class="gray_txt"
              >분산된 E-MAIL, SMS, Push, Survey를 통합 플랫폼에서 관리하는 UMS
              솔루션</span
            >
            One 플랫폼 멀티 채널 맞춤형 고객 관리 서비스
          </h2>
          <!-- work_prc// -->
          <div class="work_prc">
            <div class="inner">
              <div class="work_stepwrap">
                <ul>
                  <li>
                    <div class="icon_circle">
                      <img
                        src="@/assets/img/sub/axon/workprc_ums_01.png"
                        alt="UMS프로세스1"
                      />
                    </div>
                    <div class="work_desc">
                      <strong>AXon EMS</strong>
                      <p>
                        <span>Email Marketing System</span>
                        통합 이메일 발송 시스템
                      </p>
                    </div>
                  </li>
                  <li>
                    <div class="icon_circle">
                      <img
                        src="@/assets/img/sub/axon/workprc_ums_02.png"
                        alt="UMS프로세스2"
                      />
                    </div>
                    <div class="work_desc">
                      <strong>AXon RNS</strong>
                      <p>
                        <span>Real time Notification Service</span>
                        실시간 자동응답 서비스
                      </p>
                    </div>
                  </li>
                  <li>
                    <div class="icon_circle">
                      <img
                        src="@/assets/img/sub/axon/workprc_ums_03.png"
                        alt="UMS프로세스3"
                      />
                    </div>
                    <div class="work_desc">
                      <strong>AXon SMS</strong>
                      <p>SMS 문자메시지 발송 시스템</p>
                    </div>
                  </li>
                  <li>
                    <div class="icon_circle">
                      <img
                        src="@/assets/img/sub/axon/workprc_ums_04.png"
                        alt="UMS프로세스4"
                      />
                    </div>
                    <div class="work_desc">
                      <strong>AXon PUSH</strong>
                      <p>모바일 푸시 알림 발송 시스템</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <!-- //work_prc -->

          <!-- service_area// -->
          <ServiceInfoUMS />
          <!-- service_area// -->
          <!-- ums sub_common// -->
          <div class="sub_common find_pf">
            <div class="inner">
              <div class="find_txt">
                <h2 class="itr_ttl">
                  <span class="col_red">AXon UMS</span>
                  <strong>더 자세히 알고 싶으신가요?</strong>
                </h2>                
                <!-- <a href="#" @click.prevent="$router.push('/portfolio')" class="link_more"
                230329수정 -->
                <a href="#" @click.prevent="movePage('/service/service-inqury')" class="link_more"
                  >서비스 소개서 다운로드<span
                    >더보기화살표
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      viewBox="0 0 6 10.5"
                    >
                      <defs>
                        <clipPath id="clip-path">
                          <rect id="box" width="6" height="10.5" fill="#ff6d6d" opacity="0.47" />
                        </clipPath>
                      </defs>
                      <g id="ic__" data-name="ic_&gt;" clip-path="url(#clip-path)">
                        <path
                          id="arrow-ios-forward-fill"
                          d="M14.135,16.387a.637.637,0,0,1-.49-1.044L16.5,11.931l-2.75-3.419a.651.651,0,1,1,1.025-.8l3.075,3.82a.637.637,0,0,1,0,.808l-3.183,3.82a.637.637,0,0,1-.528.229Z"
                          transform="translate(-12.748 -6.706)"
                          fill="#777"
                        />
                      </g>
                    </svg> </span
                ></a>
              </div>
              <div class="find_desc"><img src="@/assets/img/common/bg_pdf_ums.png" alt="포트폴리오설명" /></div>
            </div>
          </div>
          <!-- //sub_common -->
        </div>
        <!-- //ums tab_content -->

        <!-- eis tab_content// -->
        <div class="tab_content eis active" v-if="activeClass.eis">
          <h2 class="h2_sub inner">
            <span class="gray_txt"
              >기업 비즈니스 분석에 최적화된 UI 솔루션, AXon EIS</span
            >
            많은 고객에게 인정 받은 시각화 솔루션
          </h2>
          <!-- work_prc// -->
          <div class="work_prc">
            <div class="inner">
              <div class="work_stepwrap">
                <ul>
                  <li>
                    <div class="icon_circle">
                      <img
                        src="@/assets/img/sub/axon/workprc_eis_01.png"
                        alt="아키텍쳐기술"
                      />
                    </div>
                    <div class="work_desc">
                      <strong>혁신적인 아키텍처</strong>
                      <p>
                        <span> 다양한 시스템 및 업무 연계를 위한</span>
                        컴포넌트 기반 엔터프라이즈 환경
                      </p>
                    </div>
                  </li>
                  <li>
                    <div class="icon_circle">
                      <img
                        src="@/assets/img/sub/axon/workprc_eis_02.png"
                        alt="시각화"
                      />
                    </div>
                    <div class="work_desc">
                      <strong>고품질 시각화</strong>
                      <p>
                        <span>High Chart 엔진을 내장한</span>
                        안정적인 시각화 엔진
                      </p>
                    </div>
                  </li>
                  <li>
                    <div class="icon_circle">
                      <img
                        src="@/assets/img/sub/axon/workprc_eis_03.png"
                        alt="시스템유연성"
                      />
                    </div>
                    <div class="work_desc">
                      <strong>유연한 시스템</strong>
                      <p>
                        <span>UNIX, LINUX, Windows 등</span>
                        다양한 플랫폼 환경 지원
                      </p>
                    </div>
                  </li>
                  <li>
                    <div class="icon_circle">
                      <img
                        src="@/assets/img/sub/axon/workprc_eis_04.png"
                        alt="기능의 편의성"
                      />
                    </div>
                    <div class="work_desc">
                      <strong>기능 편의성</strong>
                      <p>
                        <span>다양한 개인화 기능을 제공한</span>
                        시스템 활용 극대화
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <!-- //work_prc -->
          <!-- service_area// -->
          <ServiceInfo />
          <!-- service_area// -->
          <!-- sub_common// -->
          <div class="sub_common find_pf">
            <div class="inner">
              <div class="find_txt">
                <h2 class="itr_ttl">
                  <span class="col_red">AXon EIS</span>
                  <strong>더 자세히 알고 싶으신가요?</strong>
                </h2>
                <!-- <a href="#" @click.prevent="$router.push('/portfolio')" class="link_more" -->
                <a href="#" @click.prevent="movePage('/service/service-inqury-eis')"  class="link_more"
                  >서비스 소개서 다운로드<span
                    >더보기화살표
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      viewBox="0 0 6 10.5"
                    >
                      <defs>
                        <clipPath id="clip-path">
                          <rect id="box" width="6" height="10.5" fill="#ff6d6d" opacity="0.47" />
                        </clipPath>
                      </defs>
                      <g id="ic__" data-name="ic_&gt;" clip-path="url(#clip-path)">
                        <path
                          id="arrow-ios-forward-fill"
                          d="M14.135,16.387a.637.637,0,0,1-.49-1.044L16.5,11.931l-2.75-3.419a.651.651,0,1,1,1.025-.8l3.075,3.82a.637.637,0,0,1,0,.808l-3.183,3.82a.637.637,0,0,1-.528.229Z"
                          transform="translate(-12.748 -6.706)"
                          fill="#777"
                        />
                      </g>
                    </svg> </span
                ></a>
              </div>
              <div class="find_desc"><img src="@/assets/img/common/bg_pdf_eis.png" alt="pdf다운로드" /></div>
            </div>
          </div>
          <!-- //sub_common -->
        </div>
        <!-- //eis tab_content -->

        <!-- erp tab_content// -->
        <div class="tab_content erp active" v-if="activeClass.erp">
          <h2 class="h2_sub inner">
            <span class="gray_txt">네트워크 마케팅 비즈니스를 위한 #1 MLM Soft Cloud Platform</span>
            빠른 비즈니스 성장에 필요한 클라우드 기반 솔루션
          </h2>
          <!-- work_prc// -->
          <div class="work_prc">
            <div class="inner">
              <div class="work_stepwrap">
                <ul>
                  <li>
                    <div class="icon_circle">
                      <img
                        src="@/assets/img/sub/axon/workprc_erp_01.png"
                        alt="글로벌"
                      />
                    </div>
                    <div class="work_desc">
                      <strong><span>글로벌 진출 <span class="pc">지원</span> 및</span>국내 진출 지원</strong>
                      <p class="pc">
                        <span>클라우드 시스템 환경과 다국어</span>
                        <span>기능을 통한 가장 빠른 글로벌 환경을</span>
                        제공하고 국내 시장 진출 지원
                      </p>
                      <p class="mob">
                        <span>클라우드로 빠른 글로벌</span>
                        <span>및 국내 환경 지원, 다국어,</span>
                        다양한 API 제공
                      </p>
                    </div>
                  </li>
                  <li>
                    <div class="icon_circle">
                      <img
                        src="@/assets/img/sub/axon/workprc_erp_02.png"
                        alt="비즈니스"
                      />
                    </div>
                    <div class="work_desc">
                      <strong>비즈니스에 최적화된 플랫폼</strong>
                      <p>
                        <span>클라우드, 빅데이터, 인공지능 기능으로</span>
                        경쟁력 있는 비즈니스 환경을 제공
                      </p>
                    </div>
                  </li>
                  <li>
                    <div class="icon_circle">
                      <img
                        src="@/assets/img/sub/axon/workprc_erp_03.png"
                        alt="클라우드"
                      />
                    </div>
                    <div class="work_desc">
                      <strong>Compensation Plan<br>(보상플랜)</strong>
                      <p>
                        <span>MLM회사가 목표를 더 빨리</span>
                        <span>달성할 수 있도록 비즈니스에</span>
                        적합한 다양한 보상플랜을 제공합니다
                      </p>
                    </div>
                  </li>
                  <li>
                    <div class="icon_circle">
                      <img
                        src="@/assets/img/sub/axon/workprc_erp_04.png"
                        alt="클라우드 보안"
                      />
                    </div>
                    <div class="work_desc">
                      <strong>클라우드 보안 서비스</strong>
                      <p>
                        <span>데이터 보안과 사용자 접근 제어 등</span>
                        빈틈없는 보안 체계를 제공
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <!-- //work_prc -->
          <!-- service_area// -->
          <ServiceInfoERP />
          <!-- service_area// -->
          <!-- sub_common// -->
          <div class="sub_common find_pf">
            <div class="inner">
              <div class="find_txt">
                <h2 class="itr_ttl">
                  <span class="col_red">이젠 클라우드</span>
                  <strong>더 자세히 알고 싶으신가요?</strong>
                </h2>                
                <a href="https://www.ezencloud.co.kr/" target='_blank' class="link_more"
                  >자세히 보기<span
                    >더보기화살표
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      viewBox="0 0 6 10.5"
                    >
                      <defs>
                        <clipPath id="clip-path">
                          <rect id="box" width="6" height="10.5" fill="#ff6d6d" opacity="0.47" />
                        </clipPath>
                      </defs>
                      <g id="ic__" data-name="ic_&gt;" clip-path="url(#clip-path)">
                        <path
                          id="arrow-ios-forward-fill"
                          d="M14.135,16.387a.637.637,0,0,1-.49-1.044L16.5,11.931l-2.75-3.419a.651.651,0,1,1,1.025-.8l3.075,3.82a.637.637,0,0,1,0,.808l-3.183,3.82a.637.637,0,0,1-.528.229Z"
                          transform="translate(-12.748 -6.706)"
                          fill="#777"
                        />
                      </g>
                    </svg> </span
                ></a>
              </div>
              <div class="find_desc"><img src="@/assets/img/common/bg_pdf_ezen.png" alt="포트폴리오설명" /></div>
            </div>
          </div>
          <!-- //sub_common -->
        </div>
        <!-- //erp tab_content -->
      </div>
      <!-- //solution_wrap -->
      <!-- sub_common// -->
      <!-- <PortfolioBanner /> -->
      <!-- //sub_common -->
      <!-- 문의상담영역// -->
      <ConsultBanner />

      <!-- //문의상담영역 -->
    </section>
    <!-- //cont_body -->
  </div>
</template>
<script>
import ConsultBanner from "@/components/ConsultBanner.vue";
// import PortfolioBanner from "@/components/PortfolioBanner.vue";
import ServiceInfo from "./solution-compo/ServiceInfo.vue";
import ServiceInfoUMS from "./solution-compo/ServiceInfoUMS.vue";
import ServiceInfoERP from "./solution-compo/ServiceInfoERP.vue";

export default {
  // name은 파일 이름을 쓰시면 됩니다. ex) Portfolio.vue => Portfolio
  name: "Solution",
  // components 위에서 임포트한 컴포넌트를 여기에 등록하고 위에 HTML영역에 태그로 사용가능합니다.
  components: {
    ConsultBanner,
    // PortfolioBanner,
    ServiceInfo,
    ServiceInfoUMS,
    ServiceInfoERP,
  },
  data() {
    return {
      activeClass: {
        ums: "",
        eis: "",
        erp: "",
      },
    };
  },
  mounted() {
    this.activeTabBtn("erp");
  },
  methods: {
    activeTabBtn(type) {
      this.activeClass.ums = "";
      this.activeClass.eis = "";
      this.activeClass.erp = "";
      this.activeClass[type] = "active";
    },
    movePage(path) {
      if (this.$route.path !== path) {
        this.$router.push(path);
      }
    },
  },
};
</script>
