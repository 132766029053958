<template>
  <!-- content// -->
  <div id="content" class="inquiry">
    <!-- cont_body// -->
    <section class="cont_body">
      <!-- inner// -->
      <div class="inner">
        <!-- 대제목 영역// -->
        <PageTitle
          title="문의상담"
          subTitle="엔더스 전문가 그룹이 언제나 최적의 해답을 찾기 위해 최선을 다하겠습니다."
        />
        <!-- //대제목 영역 -->
      </div>
      <!-- //inner -->

      <!-- iqry_wrap// -->
      <div class="iqry_wrap">
        <div class="inner">
          <fieldset>
            <legend class="hidden">엔더스 문의 상담 신청</legend>
            <div class="form_info">
              <dl>
                <dt>성명</dt>
                <dd>
                  <input
                    type="text"
                    placeholder="이름을 입력해주세요."
                    maxlength="20"
                    v-model="consult.name"
                  />
                  <label for="" class="hidden">문의자 이름 입력란</label>
                </dd>
              </dl>

              <dl>
                <dt>연락처</dt>
                <dd>
                  <input
                    type="tel"
                    maxlength="11"
                    placeholder="번호만 입력해주세요."
                    v-model="consult.phone"
                  />
                  <label for="" class="hidden">지원자 연락처 입력란</label>
                </dd>
              </dl>

              <dl>
                <dt>이메일</dt>
                <dd>
                  <input
                    type="text"
                    maxlength="150"
                    v-model="consult.mail"
                    placeholder="이메일 주소를 입력해주세요."
                  />
                </dd>
              </dl>
              <dl>
                <dt>상담내용</dt>
                <dd>
                  <textarea
                    name=""
                    id=""
                    v-model="consult.content"
                    maxlength="1500"
                    placeholder="작은 문의도 복잡한 상담도 괜찮습니다. 프로젝트 관련하여 궁금한 사항을 남겨 주시면 확인 후 신속히 답변 드리겠습니다."
                    title="상담문의 내용 입력"
                  ></textarea>
                  <!-- <div id="" class="counting_txt">(0/1500)</div> -->
                </dd>
              </dl>
            </div>

            <div class="privacy_wrap">
              <Agreement />
              <div class="chk_area">
                <input type="checkbox" id="apply_chk" v-model="privacy" />
                <label for="apply_chk">개인정보 수집 및 이용에 동의합니다.</label>
              </div>

              <p class="confirm_txt">상기 내용으로 프로젝트 상담을 신청합니다.</p>
            </div>

            <div class="btn_area">
              <button class="btn_submit" @click.prevent="submitApplication">상담 신청</button>
              <button class="btn_submit cancle" @click.prevent="resetConsult">취소</button>
            </div>
          </fieldset>
        </div>
      </div>
      <!-- //iqry_wrap -->
    </section>
    <!-- //cont_body -->
  </div>
</template>

<script>
import PageTitle from "@/components/PageTitle.vue";
import { http } from "@/util/http";
import Agreement from "./compo/Agreement.vue";

export default {
  // name은 파일 이름을 쓰시면 됩니다. ex) Portfolio.vue => Portfolio
  name: "Inqury",
  // components 위에서 임포트한 컴포넌트를 여기에 등록하고 위에 HTML영역에 태그로 사용가능합니다.
  components: {
    PageTitle,
    Agreement
  },
  data() {
    return {
      privacy: false,
      consult: {
        name: "",
        phone: "",
        mail: "",
        content: ""
      }
    };
  },
  watch: {
    "consult.phone": function reg() {
      this.consult.phone = this.consult.phone.replace(/[^0-9]/g, "");
    }
  },
  methods: {
    resetForm() {
      this.privacy = false;
      this.consult.name = "";
      this.consult.phone = "";
      this.consult.mail = "";
      this.consult.content = "";
    },
    async submitApplication() {
      const check = Object.values(this.consult).every(n => !!n === true);

      if (check && this.privacy) {
        this.$confirm("상담 내용을 접수 하시겠습니까?", "", "question")
          .then(async () => {
            const result = await http.post("/enders/api/consult", {
              name: this.consult.name,
              phone: this.consult.phone,
              mail: this.consult.mail,
              content: this.consult.content
            });
            if (result.code === 200) {
              this.$alert("프로젝트 상담이 접수 되었습니다.", "", "success");
              this.resetForm();
            } else {
              this.$alert(
                "프로젝트 상담 내용 접수 중 오류가 발생했습니다. 다시 시도해주세요.",
                "",
                "error"
              );
            }
          })
          .catch(() => {});
      } else if (!check) {
        this.$alert("작성 되지 않은 항목이 존재합니다.", "", "warning");
      } else {
        this.$alert("개인정보 수집 및 이용에 동의 되지 않았습니다.", "", "warning");
      }
    },
    async resetConsult() {
      this.$confirm("작성을 취소하고 메인 페이지로 돌아가시겠습니까?", "", "question")
        .then(async () => {
          this.$router.push("/");
        })
        .catch(() => {});
    }
  }
};
</script>
