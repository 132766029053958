import Vue from 'vue';
import VueRouter from 'vue-router';
import Portfolio from '@/views/portfolio/Portfolio.vue';
import Cloud from '@/views/service/Cloud.vue';
import Dcx from '@/views/service/Dcx.vue';
import Ebiz from '@/views/service/Ebiz.vue';
import Solution from '@/views/service/Solution.vue';
import ServiceInqury from '@/views/service/ServiceInqury.vue';
import ServiceInquryEIS from '@/views/service/ServiceInquryEIS.vue';
import About from '@/views/about/about.vue';
import Location from '@/views/contact/Location.vue';
import Inqury from '@/views/contact/Inqury.vue';
import EndersCrew from '@/views/crew/EndersCrew.vue';
import RecruitProcess from '@/views/crew/RecruitProcess.vue';
import RecruitList from '@/views/crew/RecruitList.vue';
import RecruitApply from '@/views/crew/RecruitApply.vue';
import Home from '@/views/home/Home.vue';
import InquryConsultation from '@/views/admin/inqury/InquryConsultation.vue';
import InquryDetail from '@/views/admin/inqury/detail/InquryDetail.vue';
import ApplicantManagement from '@/views/admin/applicant/ApplicantManagement.vue';
import ApplicantDetail from '@/views/admin/applicant/detail/ApplicantDetail.vue';
import PortfolioManagement from '@/views/admin/portfolio/main/PortfolioManagement.vue';
import EnrollPortfolio from '@/views/admin/portfolio/register/EnrollPortfolio.vue';
import PortfolioDetail from '@/views/admin/portfolio/detail/PortfolioDetail.vue';
import History from '@/views/admin/history/main/History.vue';
import EnrollHistory from '@/views/admin/history/register/EnrollHistory.vue';
import HistoryDetail from '@/views/admin/history/detail/HistoryDetail.vue';
import store from '@/store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: () => import('@/layout/Layout.vue'),
    children: [
      {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
          title: '엔더스 - Enders of Total IT Service',
        },
      },
      {
        path: '/portfolio',
        name: 'Portfolio',
        component: Portfolio,
        meta: {
          title: 'Portfolio | Enders',
        },
      },
      {
        path: '/service/cloud',
        name: 'Cloud',
        component: Cloud,
        meta: {
          title: '클라우드 트랜스포메이션 | Enders',
        },
      },
      {
        path: '/service/dcx',
        name: 'Dcx',
        component: Dcx,
        meta: {
          title: '디지털 CX · UIUX |  Enders',
        },
      },
      {
        path: '/service/ebiz',
        name: 'Ebiz',
        component: Ebiz,
        meta: {
          title: 'E-BIZ 서비스 | Enders',
        },
      },
      {
        path: '/service/solution',
        name: 'Solution',
        component: Solution,
        meta: {
          title: '비즈니스 솔루션 | Enders',
        },
      },
      {
        path: '/service/service-inqury',
        name: 'ServiceInqury',
        component: ServiceInqury,
        meta: {
          title: '문의상담 | Enders',
        },
      },
      {
        path: '/service/service-inqury-eis',
        name: 'ServiceInquryEIS',
        component: ServiceInquryEIS,
        meta: {
          title: '문의상담 | Enders',
        },
      },
      {
        path: '/about',
        name: 'About',
        component: About,
        meta: {
          title: 'About | Enders',
        },
      },
      {
        path: '/contact/location',
        name: 'Location',
        component: Location,
        meta: {
          title: '오시는 길 | Enders',
        },
      },
      {
        path: '/contact/inqury',
        name: 'Inqury',
        component: Inqury,
        meta: {
          title: '문의상담 | Enders',
        },
      },
      {
        path: '/crew/enderscrew',
        name: 'EndersCrew',
        component: EndersCrew,
        meta: {
          title: '엔더스 크루 | Enders',
        },
      },
      {
        path: '/crew/recruit-process',
        name: 'RecruitProcess',
        component: RecruitProcess,
        meta: {
          title: '지원정보 | Enders',
        },
      },
      {
        path: '/crew/recruit',
        name: 'Recruit',
        component: RecruitList,
        meta: {
          title: '모집분야 | Enders',
        },
      },
      {
        path: '/crew/recruit-apply',
        name: 'RecruitApply',
        component: RecruitApply,
        meta: {
          title: '크루 지원 | Enders',
        },
      },
    ],
  },
  {
    path: '/admin/login',
    name: 'Login',
    component: () => import('@/views/admin/login/Login.vue'),
    meta: {
      title: '로그인 | Enders',
    },
  },
  {
    path: '/admin',
    component: () => import('@/layout/AdminLayout.vue'),
    children: [
      {
        path: '/admin/portfolioManagement',
        name: 'PortfolioManagement',
        component: PortfolioManagement,
        meta: {
          title: '포트폴리오 관리 | admin',
        },
      },
      {
        path: '/admin/enrollPortfolio',
        name: 'EnrollPortfolio',
        component: EnrollPortfolio,
        meta: {
          title: '포트폴리오 신규등록 | admin',
        },
      },
      {
        path: '/admin/portfolioDetail/:id',
        name: 'PortfolioDetail',
        component: PortfolioDetail,
        meta: {
          title: '포트폴리오 수정 | admin',
        },
      },
      {
        path: '/admin/inquryConsultation',
        name: 'InquryConsultation',
        component: InquryConsultation,
        meta: {
          title: '문의사항 조회 | admin',
        },
      },
      {
        path: '/admin/inquryDetail/:id',
        name: 'InquryDetail',
        component: InquryDetail,
        meta: {
          title: '문의사항 상세 | admin',
        },
      },
      {
        path: '/admin/applicantManagement',
        name: 'ApplicantManagement',
        component: ApplicantManagement,
        meta: {
          title: '지원자 관리 | admin',
        },
      },
      {
        path: '/admin/applicantDetail/:id',
        name: 'ApplicantDetail',
        component: ApplicantDetail,
        meta: {
          title: '지원자 관리 수정 | admin',
        },
      },
      {
        path: '/admin/history',
        name: 'History',
        component: History,
        meta: {
          title: '연혁관리 | admin',
        },
      },
      {
        path: '/admin/enrollHistory',
        name: 'EnrollHistory',
        component: EnrollHistory,
        meta: {
          title: '연혁 신규 등록 | admin',
        },
      },
      {
        path: '/admin/historyDetail/:id',
        name: 'HistoryDetail',
        component: HistoryDetail,
        meta: {
          title: '연혁 수정 | admin',
        },
      },
    ],
    beforeEnter: (to, from, next) => {
      if(store.state.userStatus){
        next();
        return
      }
      next('/admin/login');
    }
  },
];

const router = new VueRouter({
  mode: 'history',
  // eslint-disable-next-line no-unused-vars
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
      };
    }
    return { x: 0, y: 0 };
  },
  base: process.env.BASE_URL,
  routes,
});

export default router;
