<template>
  <div id="content" class="apply">
    <!-- cont_body// -->
    <section class="cont_body">
      <!-- inner// -->
      <div class="inner">
        <PageTitle title="크루 지원" subTitle="엔더스는 여러분의 지원을 기다리고 있습니다." />
      </div>
      <!-- //inner -->

      <!-- apply_wrap// -->
      <div class="apply_wrap">
        <div class="inner">
          <fieldset>
            <legend class="hidden">엔더스 입사지원 신청</legend>
            <div class="form_info">
              <dl>
                <dt>지원분야<span class="col_red">*</span></dt>
                <dd class="select_rct">
                  <select v-model="applicant.recruitType">
                    <option value="">선택</option>
                    <option value="front">프론트엔드 개발자</option>
                    <option value="back">백엔드 개발자</option>
                    <option value="design">디자이너</option>
                    <option value="pm">SI사업 PM</option>
                    <option value="plan">웹기획자</option>
                  </select>
                </dd>
              </dl>

              <dl>
                <dt>지원자명<span class="col_red">*</span></dt>
                <dd>
                  <input type="text" placeholder="이름을 입력해주세요." v-model="applicant.name" />
                  <label for="" class="hidden">지원자 이름 입력란</label>
                </dd>
              </dl>

              <dl>
                <dt>연락처<span class="col_red">*</span></dt>
                <dd>
                  <input type="tel"
                    placeholder="번호만 입력해주세요."
                    v-model="applicant.phone"
                    maxlength="11"
                  />
                  <label for="" class="hidden">지원자 연락처 입력란</label>
                </dd>
              </dl>

              <dl>
                <dt>이메일<span class="col_red">*</span></dt>
                <dd>
                  <input type="text" maxlength="150" placeholder="이메일 주소를 입력해주세요." v-model="applicant.mail" />
                </dd>
              </dl>

              <dl class="submit_area">
                <dt>이력서 제출<span class="col_red">*</span></dt>
                <dd>
                  <div class="state_added" v-if="isResume">
                    <div class="data_box">
                      <strong class="data_name">{{ applicant.resume.title }}</strong>
                      <span class="data_size"
                        >{{ Math.floor(applicant.resume.size / 1024) }} KB</span
                      >
                    </div>
                    <button class="btn_remove" @click.prevent="deleteResume">
                      <img src="@/assets/img/common/btn_remove.svg" alt="삭제버튼" />
                    </button>
                  </div>
                  <label for="resume" class="btn_add" v-else>
                    <img src="@/assets/img/common/btn_add.svg" alt="파일첨부추가버튼" />
                    <input type="file" id="resume" hidden @change="inputResume" />
                  </label>
                  <p class="gray_txt">*이력서 양식은 자유이며 한 파일만 첨부 가능합니다.</p>
                </dd>
              </dl>

              <dl class="submit_area">
                <dt>포트폴리오 제출</dt>
                <dd>
                  <div class="state_added" v-for="(pofol, idx) in applicant.portfolio" :key="idx">
                    <div class="data_box">
                      <strong class="data_name">{{ pofol.title }}</strong>
                      <span class="data_size">{{ Math.floor(pofol.size / 1024) }} KB</span>
                    </div>
                    <button class="btn_remove" @click.prevent="deletePortfolio(idx)">
                      <img src="@/assets/img/common/btn_remove.svg" alt="삭제버튼" />
                    </button>
                  </div>
                  <label for="portfolio" class="btn_add" v-if="!isPortfolio">
                    <img src="@/assets/img/common/btn_add.svg" alt="파일첨부추가버튼" />
                    <input type="file" id="portfolio" hidden @change="inputPortfolio" />
                  </label>
                </dd>
              </dl>
            </div>

            <div class="privacy_wrap">
              <Agreement />
              <div class="chk_area">
                <input type="checkbox" id="apply_chk" v-model="privacy" />
                <label for="apply_chk">개인정보 수집에 동의합니다.</label>
              </div>
            </div>
            <div class="btn_area">
              <button class="btn_submit" @click.prevent="submitApplication">지원서 제출</button>
            </div>
          </fieldset>
        </div>
      </div>
      <!-- //apply_wrap -->
    </section>
    <!-- //cont_body -->
  </div>
</template>

<script>
import PageTitle from '@/components/PageTitle.vue';
import recruitApi from '@/apis/recruit';
import Agreement from './apply-compo/Agreement.vue';

export default {
  name: 'RecruitApply',
  components: {
    PageTitle,
    Agreement,
  },
  mounted() {
    this.applicant.recruitType = this.$route.params.type ? this.$route.params.type : '';
  },
  data() {
    return {
      isResume: false,
      isPortfolio: false,
      privacy: false,
      applicant: {
        recruitType: '',
        name: '',
        phone: '',
        mail: '',
        resume: {
          title: '',
          size: '',
          file: '',
        },
        portfolio: [],
      },
    };
  },
  watch: {
    'applicant.phone': function reg() {
      this.applicant.phone = this.applicant.phone.replace(/[^0-9]/g, '');
    },
  },
  methods: {
    inputResume(event) {
      console.dir(event.target);
      if (event.target.files.length > 0) {
        this.applicant.resume.title = event.target.files[0].name;
        this.applicant.resume.size = event.target.files[0].size;
        // eslint-disable-next-line prefer-destructuring
        this.applicant.resume.file = event.target.files[0];
        this.isResume = true;
      }
    },
    deleteResume() {
      this.applicant.resume.title = '';
      this.applicant.resume.size = '';
      this.applicant.resume.file = '';
      this.isResume = false;
    },
    inputPortfolio(event) {
      if (event.target.files.length > 0) {
        const inputFile = event.target.files[0];
        this.applicant.portfolio.push({
          title: inputFile.name,
          size: inputFile.size,
          file: inputFile,
        });
        this.isPortfolio = this.applicant.portfolio.length === 3;
      }
    },
    deletePortfolio(idx) {
      this.applicant.portfolio.splice(idx, 1);
      this.isPortfolio = false;
    },
    async submitApplication() {
      const check = Object.values(this.applicant).every((n) => !!n === true);

      if (check && this.isResume && this.privacy) {
        this.$confirm('지원서를 제출 하시겠습니까?', '', 'question')
          .then(async () => {
            const result = await recruitApi.applicantPost(this.applicant);
            if (result.code === 200) {
              this.$alert('지원서 제출이 완료되었습니다.', '', 'success');
              this.$router.push('/crew/enderscrew');
            } else {
              this.$alert('지원서 제출중 오류가 발생했습니다. 다시 시도해주세요.', '', 'error');
            }
          })
          .catch(() => {});
      } else if (!check || !this.isResume) {
        this.$alert('작성 되지 않은 항목이 존재합니다.', '', 'warning');
      } else {
        this.$alert('개인정보 수집 및 이용에 동의 되지 않았습니다.', '', 'warning');
      }
    },
  },
};
</script>
