<template>
  <div class="service_area">
    <div class="inner">
      <h3 class="h3_ttl">Service</h3>
      <!-- service_list// -->
      <div class="service_list type_pc">
        <SeviceInfoItem v-for="(item, idx) in serviceItem" :key="idx" :pcItem="item"/>
      </div>
      <!-- //type_pc -->

      <!-- service_list// -->
      <div class="service_list type_mb">
        <Swiper :options="swiperOptions">
          <SwiperSlide class="swiper-slide" v-for="(item, idx) in serviceItem" :key="idx" >
            <SeviceInfoItem :pcItem="item"/>
          </SwiperSlide>
          <div class="swiper-pagination mb_srv_pager swiper-pagination-bullets" slot="pagination"></div>
        </Swiper>
      </div>
      <!-- //type_mb-->
      <!-- //service_list -->
    </div>
  </div>
</template>

<script>
import SeviceInfoItem from './SeviceInfoItem.vue';

export default {
  name: 'ServiceInfo',
  components: {
    SeviceInfoItem,
  },
  data() {
    return {
      swiperOptions: {
        spaceBetween: 30,
        pagination: {
          el: '.swiper-pagination',
        },
      },
      serviceItem: [
        {
          title: 'E-BIZ 서비스',
          content:
            '비즈니스에 대한 심층 분석과 시스템에 최적화된 서비스 운영까지 세상을 놀라게 할 디지털 혁신을 함께 만들어 드립니다.',
          img: require('@/assets/img/mobile/svc_ebiz.png'),
          path: '/service/ebiz',
        },
        {
          title: '클라우드 트랜스포메이션',
          content:
            '클라우드 IT를 가장 빠르게 만나는 방법. 경제적인 클라우드 도입/운영 방안에 대한 컨설팅을 시작해보세요.',
          img: require('@/assets/img/mobile/svc_cloud.png'),
          path: '/service/cloud',
        },
        {
          title: '디지털 CX · UIUX',
          content:
            '모든 요소를 고객 경험 중심으로 분해하고 재결합합니다. 소통, 분석, 효율 중심의 차별화된 UIUX 구축으로 감동을 전해 드립니다.',
          img: require('@/assets/img/mobile/svc_uiux.png'),
          path: '/service/dcx',
        },
        {
          title: '비즈니스 솔루션',
          content:
            '효율적인 비즈니스 업무를 위한 최적의 솔루션, Axon UMS와 Axon EIS 솔루션으로 최적의 디지털 비즈니스를 경험해보세요.',
          img: require('@/assets/img/mobile/svc_solution.png'),
          path: '/service/solution',
        },
      ],
    };
  },
};
</script>
