<template>
  <!-- sub_common// -->
  <div class="sub_common find_pf">
    <div class="inner">
      <div class="find_txt">
        <h2 class="itr_ttl">
          <span class="col_red">Find out!</span>
          <strong>다양한 포트폴리오를 만나보세요.</strong>
        </h2>
        <a href="#" @click.prevent="$router.push('/portfolio')" class="link_more"
          >MORE<span
            >더보기화살표
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 6 10.5"
            >
              <defs>
                <clipPath id="clip-path">
                  <rect id="box" width="6" height="10.5" fill="#ff6d6d" opacity="0.47" />
                </clipPath>
              </defs>
              <g id="ic__" data-name="ic_&gt;" clip-path="url(#clip-path)">
                <path
                  id="arrow-ios-forward-fill"
                  d="M14.135,16.387a.637.637,0,0,1-.49-1.044L16.5,11.931l-2.75-3.419a.651.651,0,1,1,1.025-.8l3.075,3.82a.637.637,0,0,1,0,.808l-3.183,3.82a.637.637,0,0,1-.528.229Z"
                  transform="translate(-12.748 -6.706)"
                  fill="#777"
                />
              </g>
            </svg> </span
        ></a>
      </div>
      <div class="find_desc"><img src="@/assets/img/common/bg_pf.png" alt="포트폴리오설명" /></div>
    </div>
  </div>
  <!-- //sub_common -->
</template>

<script>
export default {
  name: "PortfolioBanner"
};
</script>
