<template>
    <div id="content" class="itr_crew">
        <!-- cont_body// -->
        <section class="cont_body">

            <!-- sub_banner// -->
            <div class="sub_banner">
                <div class="inner">
                    <div class="txt_area">
                        <p class="small_txt">평범함 속에 다름을 찾는 IT 종결자들</p>
                        <p class="main_txt">우리는 독특한 생각을 가진 엔더스 크루입니다.</p>
                    </div>
                    <div class="bnnr_img">
                        <img src="@/assets/img/sub/crew/bannr_bg.png" alt="엔더스 크루">
                    </div>
                </div>
            </div>
            <!-- //sub_banner -->

            <!-- visual_content// -->
            <div class="visual_content">
                <div class="inner">
                    <div>
                        <div class="txt_cont">
                            <h3 class="cont_ttl">
                                <strong>
                                    <span class="mb_block">한 번도</span>
                                    멈추지 않은 성장의 시간!
                                </strong>
                            </h3>
                            <div class="dv_line"></div>
                            <p>
                                <span class="mb_block">2013년도 설립부터 지속 성장한 영업매출이 엔더스의 실력을 증명합니다.</span>
                                <span class="mb_block">멈추지 않고 더 높은 목표를 향해 끊임없이 도전하는 우리는</span>
                                엔더스 절대자들입니다.
                            </p>
                        </div>
                        <div class="img_cont"><img src="@/assets/img/sub/crew/crew_visual_01.png" alt="엔더스성장"></div>
                    </div>

                    <div>
                        <div class="txt_cont">
                            <h3 class="cont_ttl">
                                <strong>
                                    <span class="mb_block">NICE 평가</span>
                                    산업 활동 '최상위' 기업선정
                                </strong>
                            </h3>
                            <div class="dv_line"></div>
                            <p>
                                <span class="mb_block">엔더스가 이룩한 성장에는 무엇이든 만든다 라는 자신감과 </span>
                                <span class="mb_block">창의적 발상이 있기에 가능했습니다.</span>
                                <span class="mb_block">우리는 열린 사고와 자신감을 가지고 기회를 찾아</span>
                                더 나은 미래를 위한 도전과 연구를 멈추지 않겠습니다.
                            </p>
                        </div>
                        <div class="img_cont"><img src="@/assets/img/sub/crew/crew_visual_02.png" alt="나이스평가"></div>
                    </div>

                    <div>
                        <div class="txt_cont">
                            <h3 class="cont_ttl">
                                <strong>
                                    <span class="mb_block">신입사원 공채</span>
                                    끝없는 인재발굴!
                                </strong>
                            </h3>
                            <div class="dv_line"></div>
                            <p>
                                <span class="mb_block">경력직 채용은 기본! </span>
                                <span class="mb_block">열정과 가능성을 겸비한 신입사원 공채를 진행합니다. </span>
                                <span class="mb_block">엔더스는 언제나 전문가 양성을 위한 인재 발굴과</span>
                                자기계발 지원을 아끼지 않습니다 .
                            </p>
                        </div>
                        <div class="img_cont"><img src="@/assets/img/sub/crew/crew_visual_03.png" alt="신입공채"></div>
                    </div>
                </div>
            </div>
            <!-- //visual_content -->

            <!-- enders_culture// -->
            <div class="enders_culture">
                <div class="inner">
                    <h3 class="h3_ttl">엔더스 크루 문화</h3>
                    <div class="culture_content">
                            <div>
                            <dl>
                                <dt>엔더스 <span class="col_blu">Life</span></dt>
                                <dd>
                                    <div class="icon_block"><img src="@/assets/img/sub/crew/culture_life_01.png" alt="탄력근무제"></div>
                                    <p>탄력근무제</p>
                                </dd>
                                <dd>
                                    <div class="icon_block"><img src="@/assets/img/sub/crew/culture_life_02.png" alt="연도한도없음"></div>
                                    <p>
                                        <span>연차 한도</span>
                                        ZERO
                                    </p>
                                </dd>
                                <dd>
                                    <div class="icon_block"><img src="@/assets/img/sub/crew/culture_life_03.png" alt="야근타파"></div>
                                    <p>야근 타파</p>
                                </dd>
                                <dd>
                                    <div class="icon_block"><img src="@/assets/img/sub/crew/culture_life_04.png" alt="부대시설"></div>
                                    <p>
                                        <span>속초 리조트</span>
                                        '모히또'
                                    </p>
                                </dd>
                            </dl>
                            <div class="culture_txt">
                                <strong>
                                    <span>“쉬고 싶을 때, 쉬는 것이 진정한 휴식이지!”</span>
                                    고집 있는 철학이 만든 재충전 라이프를 소개합니다.
                                </strong>
                                <p>
                                    <span>절차는 간소화, 무한으로 즐기는 연차한도 ZERO 라이프! </span>
                                    휴일은 소중한 사람들과 함께 엔더스 리조트에서 보내 보세요.
                                </p>
                                <p>
                                    엔더스 크루의 우아한 워라벨을 위해 탄력근무제와 야근 타파를 적극 강요합니다!
                                </p>
                            </div>
                            </div>

                            <div>
                            <dl>
                                <dt>엔더스 <span class="col_blu">Flex</span></dt>
                                <dd>
                                    <div class="icon_block"><img src="@/assets/img/sub/crew/culture_flex_01.png" alt="장비"></div>
                                    <p>
                                        <span>2년 마다</span>
                                        최신 장비
                                    </p>
                                </dd>
                                <dd>
                                    <div class="icon_block"><img src="@/assets/img/sub/crew/culture_flex_02.png" alt="생일면접선물"></div>
                                    <p>
                                        <span>생일&명절</span>
                                        선물
                                    </p>
                                </dd>
                                <dd>
                                    <div class="icon_block"><img src="@/assets/img/sub/crew/culture_flex_03.png" alt="도서구매지원"></div>
                                    <p>도서 구매</p>
                                </dd>
                                <dd>
                                    <div class="icon_block"><img src="@/assets/img/sub/crew/culture_flex_04.png" alt="자격증및자기개발지원"></div>
                                    <p>
                                        <span>자격증 &</span>
                                        자기 계발
                                    </p>
                                </dd>
                            </dl>
                            <div class="culture_txt">
                                <strong>
                                    <span>누군가 쓰던 장비, 답답한 PC환경, 부담스러운 자기계발 비용..</span>
                                    엔더스 크루만을 위한 쿨~한 FLEX!
                                </strong>
                                <p>
                                    <span>NEW 장비로 시작해서 2년마다 최신 장비로 교체하는 5G 라이프!</span>
                                    도서, 생일, 명절선물 등 이벤트와 경조사부터 자기계발 노력가 크루를 위한 든든한 후원자!
                                </p>
                            </div>
                            </div>

                            <div>
                            <dl>
                                <dt>엔더스 <span class="col_blu">Care</span></dt>
                                <dd>
                                    <div class="icon_block"><img src="@/assets/img/sub/crew/culture_care_01.png" alt="4대보험및퇴직금"></div>
                                    <p>
                                        <span>4대보험</span>
                                        & 퇴직금
                                    </p>
                                </dd>
                                <dd>
                                    <div class="icon_block"><img src="@/assets/img/sub/crew/culture_care_02.png" alt="직장인건강검진"></div>
                                    <p>
                                        <span>직장인</span>
                                        건강검진
                                    </p>
                                </dd>
                                <dd>
                                    <div class="icon_block"><img src="@/assets/img/sub/crew/culture_care_03.png" alt="국가채용복지사업"></div>
                                    <p>
                                        <span>국가채용</span>
                                        복지사업
                                    </p>
                                </dd>
                                <dd>
                                    <div class="icon_block"><img src="@/assets/img/sub/crew/culture_care_04.png" alt="장기근속포상"></div>
                                    <p>
                                        <span>장기근속</span>
                                        포상
                                    </p>
                                </dd>
                            </dl>
                            <div class="culture_txt">
                                <strong>
                                    <span>“요즘 4대 보험도 잘 안 들어준다는데..”</span>
                                    혹시하는 불안감은 접어두세요.
                                </strong>
                                <p>
                                    <span>엔더스 크루 모두가 안심하고 근로 문화를 누릴 수 있도록 오늘도 최선을 다합니다.</span>
                                    4대 보험, 퇴직금, 건강검진은 물론 국가복지사업도 적극적으로 참여하고 있답니다!
                                </p>
                            </div>
                            </div>
                    </div>
                    <!-- //culture_content -->

                    <div class="crew_banner">
                        <div class="bannr_txt">
                            <p>NOW OR NEVER</p>
                            <p>
                                <span>엔더스 크루가 될 수 있는 다양한 기회!</span>
                                지금 바로 지원해보세요.
                            </p>
                        </div>
                        <a href="#" @click.prevent="movePage('/crew/recruit')" class="link_iqr">크루모집<span>더보기화살표
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 6 10.5">
                            <defs>
                                <clipPath id="clip-path">
                                <rect id="box" width="6" height="10.5" fill="#ff6d6d" opacity="0.47"/>
                                </clipPath>
                            </defs>
                            <g id="ic__" data-name="ic_&gt;" clip-path="url(#clip-path)">
                                <path id="arrow-ios-forward-fill" d="M14.135,16.387a.637.637,0,0,1-.49-1.044L16.5,11.931l-2.75-3.419a.651.651,0,1,1,1.025-.8l3.075,3.82a.637.637,0,0,1,0,.808l-3.183,3.82a.637.637,0,0,1-.528.229Z" transform="translate(-12.748 -6.706)" fill="#777"/>
                            </g>
                            </svg>
                        </span></a>
                    </div>
                    <!-- //crew_banner -->
                </div>
            </div>
            <!-- //enders_culture -->
        </section>
      <!-- //cont_body -->
  </div>
</template>
<script>
export default {
  // name은 파일 이름을 쓰시면 됩니다. ex) Portfolio.vue => Portfolio
  name: 'EndersCrew',
  methods: {
    movePage(path) {
      if (this.$route.path !== path) {
        this.$router.push(path);
      }
    },
  },
};
</script>
