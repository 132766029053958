<template>
  <dl>
    <dt>
      <strong>
        [필수] 개인정보 수집 및 이용 동의
      </strong>
      <span class="desc">
        엔더스는 아래의 목적으로 개인정보를 수집 및 이용하며, 개인정보 유출방지를 위해 최선을
        다하겠습니다.
      </span>
    </dt>
    <dd>
      <!-- policy_cont// -->
      <div class="policy_cont">
        <ol>
          <li>
            1. 개인정보 수집 목적
            <p>1)입사 전형의 진행, 지원자의 직무수행능력 평가</p>
          </li>
          <li>
            2. 개인정보 수집 항목
            <p>1) 지원자 개인정보 (지원자명, 연락처, 이메일, 지원분야)</p>
            <p>2) 지원자 이력서</p>
          </li>
          <li>
            3. 선택적 수집 항목
            <p>1) 포트폴리오</p>
          </li>
          <li>
            4. 보유기간
            <p>1) 지원전형 최종합격 시: 지원전형 종료 후 지체없이 파기</p>
            <p>2) 지원전형 불합격 시: 지체없이 파기</p>
          </li>
        </ol>
      </div>
      <!-- //policy_cont -->
    </dd>
  </dl>
</template>

<script>
export default {
  name: 'Agreement',
};
</script>

<style></style>
