<template>
  <!-- content// -->
  <div id="content" class="main_cont">
    <!-- cont_body// -->
    <section class="cont_body">
      <MainSwiper />
      <VisualIntro />
      <ServiceInfo />
      <PortfolioInfo />
      <WelcomeCrew />
      <ConsultBanner />
    </section>
    <RecruitPopup v-if="toggle" @close-popup="toggle = false"/>
  </div>
</template>

<script>
import ConsultBanner from "@/components/ConsultBanner.vue";
import RecruitPopup from "@/components/RecruitPopup.vue";
import MainSwiper from "./compo/MainSwiper.vue";
import VisualIntro from "./compo/VisualIntro.vue";
import ServiceInfo from "./compo/ServiceInfo.vue";
import PortfolioInfo from "./compo/PortfolioInfo.vue";
import WelcomeCrew from "./compo/WelcomeCrew.vue";

export default {
  name: "Home",
  components: {
    MainSwiper,
    VisualIntro,
    ServiceInfo,
    PortfolioInfo,
    WelcomeCrew,
    ConsultBanner,
    RecruitPopup
  },
  data() {
    return {
      toggle: new Date() > new Date("2022/06/20 09:00:00") && new Date() < new Date("2022/07/21")
    };
  },
  mounted() {
    if(this.$cookies.get("popup-disable")){
      this.toggle = false;
    }
  },
};
</script>
