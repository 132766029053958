<template>
  <div class="list_item">
    <div class="lf_img"><img :src="pcItem.img" /></div>
    <div class="rt_txt">
      <strong>{{ pcItem.title }}</strong>
      <p>
        {{ pcItem.content }}
      </p>
      <a href="javascript:;" class="link_more" @click="$router.push(pcItem.path)"
        >MORE<span
          >더보기화살표
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 6 10.5"
          >
            <defs>
              <clipPath id="clip-path">
                <rect id="box" width="6" height="10.5" fill="#ff6d6d" opacity="0.47" />
              </clipPath>
            </defs>
            <g id="ic__" data-name="ic_&gt;" clip-path="url(#clip-path)">
              <path
                id="arrow-ios-forward-fill"
                d="M14.135,16.387a.637.637,0,0,1-.49-1.044L16.5,11.931l-2.75-3.419a.651.651,0,1,1,1.025-.8l3.075,3.82a.637.637,0,0,1,0,.808l-3.183,3.82a.637.637,0,0,1-.528.229Z"
                transform="translate(-12.748 -6.706)"
                fill="#777"
              />
            </g>
          </svg> </span
      ></a>
      <a href="javascript:;" class="mb_link_more" @click="$router.push(pcItem.path)"
        ><span class="hide">화살표</span>
        <svg xmlns="http://www.w3.org/2000/svg" width="40.5" height="32" viewBox="0 0 40.5 32">
          <g id="btn_mobilemore_serv" transform="translate(0.5)">
            <rect id="btn_base" width="40" height="32" rx="16" fill="none" />
            <path
              id="패스_2025"
              data-name="패스 2025"
              d="M353.667,3680l6,6-6,6"
              transform="translate(-335.167 -3669.5)"
              fill="none"
              stroke="#888"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            />
            <path
              id="패스_2026"
              data-name="패스 2026"
              d="M354.667,3688h-24"
              transform="translate(-330.167 -3671.5)"
              fill="none"
              stroke="#888"
              stroke-linecap="round"
              stroke-width="2"
            />
          </g></svg
      ></a>
    </div>
  </div>
</template>

<script>
export default {
  name: "SeviceInfoPcItem",
  props: {
    pcItem: {
      type: Object,
      default: () => {}
    }
  }
};
</script>
