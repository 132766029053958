<template>
  <div class="graybox">
    <div class="grid-area">
      <table class="grid">
        <caption>그리드 정보</caption>
        <colgroup>
          <col />
          <col />
          <col />
          <col />
          <col />
          <col />
          <col />
          <col />
          <col />
        </colgroup>
        <thead>
          <tr>
            <th scope="col">NO</th>
            <th scope="col">문의자명</th>
            <th scope="col">지원분야</th>
            <th scope="col">연락처</th>
            <th scope="col">이메일</th>
            <th scope="col">진행 상황</th>
            <th scope="col">비고</th>
            <th scope="col">지원 일시</th>
            <th scope="col">수정 일시</th>
          </tr>
        </thead>
        <tbody>
          <!-- 데이터가 있을 경우// -->
          <tr v-for="(item, idx) in list" :key="item.applicant_id">
            <td>{{ pageInfo.totalNum - (idx + no) }}</td>
            <td>
              <a
                href="javascript:;"
                @click="$router.push({ path: `/admin/applicantDetail/${item.applicant_id }`})"
                class="col_blue bold"
                >{{ item.name }}</a
              >
            </td>
            <td>{{ item.apply_type }}</td>
            <td>{{ item.phone ? item.phone : item.email }}</td>
            <td>{{ item.email }}</td>
            <td>{{ item.status }}</td>
            <td class="tal">{{ item.memo }}</td>
            <td>{{ changeDateTime(item.reg_date) }}</td>
            <td>{{ changeDateTime(item.update_date) }}</td>
          </tr>
          <!-- //데이터가 있을 경우 -->

          <!-- 데이터가 없을 경우// -->
          <tr v-if="!list.length">
            <td colspan="9" class="no_data">등록된 내용이 없습니다.</td>
          </tr>
          <!-- //데이터가 없을 경우 -->
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { datetimeToString } from "@/util/datetime";

export default {
  name: 'ApplicantList',
  props: {
    list: {
      type: Array,
      default: () => []
    },
    pageInfo: {
      type: Object,
      default: () => ({ totalNum: 0, pageNum: 1 })
    }
  },
  computed: {
    no() {
      if(this.pageInfo.pageNum > 1){
        return 12 * (this.pageInfo.pageNum - 1);
      }
      return 0
    }
  },
  methods: {
    changeDateTime(date) {
      if (date) {
        const parsingData = new Date(date);
        return datetimeToString(parsingData);
      }
      return "";
    }
  },
};
</script>

<style></style>
