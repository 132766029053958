<template>
  <div class="iqr_area">
    <div class="inner">
      <div class="iqr_box">
        <div class="iqr_txt">
          <p>
            <span class="mb_block">부담 없이 프로젝트 상담을 받아보세요.</span> 작은 고민도 놓치지
            않고 최선의 개발 방안을 제안하겠습니다.
          </p>
          <p><strong>엔더스 전문가와 상담해보세요!</strong></p>
        </div>
        <a href="#" @click.prevent="movePage('/contact/inqury')" class="link_iqr"
          >문의상담<span
            >더보기화살표
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 6 10.5"
            >
              <defs>
                <clipPath id="clip-path">
                  <rect id="box" width="6" height="10.5" fill="#ff6d6d" opacity="0.47" />
                </clipPath>
              </defs>
              <g id="ic__" data-name="ic_&gt;" clip-path="url(#clip-path)">
                <path
                  id="arrow-ios-forward-fill"
                  d="M14.135,16.387a.637.637,0,0,1-.49-1.044L16.5,11.931l-2.75-3.419a.651.651,0,1,1,1.025-.8l3.075,3.82a.637.637,0,0,1,0,.808l-3.183,3.82a.637.637,0,0,1-.528.229Z"
                  transform="translate(-12.748 -6.706)"
                  fill="#777"
                />
              </g>
            </svg>
          </span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ConsultBanner",
  methods: {
    movePage(path) {
      if (this.$route.path !== path) {
        this.$router.push(path);
      }
    }
  }
};
</script>

<style></style>
