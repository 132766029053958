<template>
  <div class="graybox">
    <div class="list-area">
      <ul>
        <li>
          <label>문의 서비스</label>
          <div class="list-item">
            <p class="inline-txt">{{ detail.consult_type }}</p>
          </div>
        </li>
        <li>
          <label>진행 상황</label>
          <div class="list-item">
            <div class="select">
              <select title="옵션 선택" v-model="updateInfo.status">
                <option value="상담요청">상담요청</option>
                <option value="연락완료">연락완료</option>
                <option value="상담종결">상담종결</option>
                <option value="스팸문의">스팸문의</option>
              </select>
            </div>
          </div>
        </li>
        <li class="col-full" style="border-top:1px solid #E1E1E1">
          <label class="required">문의자명</label>
          <div class="list-item">
            <p class="inline-txt">{{ detail.name }}</p>
          </div>
        </li>
        <li class="col-full">
          <label class="required">연락처</label>
          <div class="list-item">
            <p class="inline-txt">{{ detail.phone }}</p>
          </div>
        </li>
        <li class="col-full">
          <label class="required">이메일</label>
          <div class="list-item">
            <p class="inline-txt">{{ detail.email }}</p>
          </div>
        </li>
        <li class="col-full">
          <label>문의사항</label>
          <div class="list-item">
            <p class="inline-txt">{{ detail.content }}</p>
          </div>
        </li>
        <li class="col-full">
          <label>비고</label>
          <div class="list-item">
            <input type="text" placeholder="" v-model="updateInfo.memo" />
          </div>
        </li>
        <li class="col-full">
          <label>등록 일시</label>
          <div class="list-item">
            <p class="inline-txt">{{ changeDateTime(detail.reg_date) }}</p>
          </div>
        </li>
        <li class="col-full">
          <label>수정 일시</label>
          <div class="list-item">
            <p class="inline-txt">{{ changeDateTime(detail.update_date) }}</p>
          </div>
        </li>
      </ul>
    </div>
    <ListBtn />
    <div class="btn-wrap">
      <button type="button" class="btn big" @click="$emit('delete-inqury')">삭제</button>
      <button type="button" class="btn big fullblue" @click="$emit('update-inqury', updateInfo)">
        저장
      </button>
    </div>
  </div>
</template>

<script>
import ListBtn from "@/components/admin/ListBtn.vue";
import { datetimeToString } from "@/util/datetime";

export default {
  name: "EditContent",
  components: {
    ListBtn
  },
  props: {
    detail: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      updateInfo: {
        status: "상담요청",
        memo: ""
      }
    };
  },
  watch: {
    detail() {
      this.updateInfo.status = this.detail.status;
      this.updateInfo.memo = this.detail.memo;
    }
  },
  methods: {
    changeDateTime(date) {
      if (date) {
        const parsingData = new Date(date);
        return datetimeToString(parsingData);
      }
      return "";
    }
  }
};
</script>
