<template>
  <div class="title">
    <h2>{{title}}</h2>
    <span v-if="subTitle">{{subTitle}}</span>
  </div>
</template>

<script>
export default {
  name: 'AdminPageTitle',
  props: {
    title: {
      type: String,
      default: '',
    },
    subTitle: {
      type: String,
      default: '',
    },
  },
  
};
</script>
