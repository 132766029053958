<template>
  <div id="content">
    <section class="cont_head">
      <AdminPageTitle title="문의 상담 이력 수정" />
    </section>

    <section class="cont_body">
      <fieldset>
        <legend>조건 및 추출조건 선택</legend>
        <EditContent :detail="detail" @delete-inqury="deleteInqury" @update-inqury="updateInqury" />
      </fieldset>
    </section>
  </div>
</template>

<script>
import AdminPageTitle from "@/components/admin/AdminPageTitle.vue";
import consultApi from "@/apis/consult";
import EditContent from "./compo/EditContent.vue";

export default {
  // name은 파일 이름을 쓰시면 됩니다. ex) Portfolio.vue => Portfolio
  name: "InquryDetail",
  // components 위에서 임포트한 컴포넌트를 여기에 등록하고 위에 HTML영역에 태그로 사용가능합니다.
  components: {
    AdminPageTitle,
    EditContent
  },
  mounted() {
    this.init();
  },
  data() {
    return {
      detail: {}
    };
  },
  methods: {
    async init() {
      const result = await consultApi.getConsultDetail(this.$route.params.id);
      if (result.code === 500) {
        await this.$alert("데이터 송신 중 오류가 발생했습니다.", "", "error");
        this.$router.push("/admin/inquryConsultation");
        return;
      }
      this.detail = result.result;
    },
    async deleteInqury() {
      this.$confirm("삭제된 정보는 복구가 불가합니다. 정말 삭제하겠습니까?", "", "warning")
        .then(async () => {
          const result = await consultApi.deleteConsultDetail(this.$route.params.id);
          if (result.code === 500) {
            await this.$alert("데이터 삭제 중 오류가 발생했습니다.", "", "error");
            return;
          }
          await this.$alert("삭제 되었습니다.", "", "success");
          this.$router.push("/admin/inquryConsultation");
        })
        .catch(() => {});
    },
    async updateInqury(data) {
      this.$confirm("입력 정보를 업데이트 하시겠습니까?", "", "question")
        .then(async () => {
          const result = await consultApi.updateConsultDetail(this.$route.params.id, data);
          if (result.code === 500) {
            await this.$alert("데이터 업데이트 중 오류가 발생했습니다.", "", "error");
            return;
          }
          await this.$alert("업데이트 되었습니다.", "", "success");
          this.$router.push("/admin/inquryConsultation");
        })
        .catch(() => {});
    }
  }
};
</script>
