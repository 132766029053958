<template>
  <div class="graybox">
    <div class="above clear">
      <button
        type="button"
        class="btn fullblue fr"
        @click.prevent="movePage('/admin/enrollPortfolio')"
      >
        등록
      </button>
    </div>
    <div class="grid-area">
      <table class="grid">
        <caption>
          그리드 정보
        </caption>
        <colgroup>
          <col />
          <col />
          <col />
          <col />
          <col />
          <col />
          <col />
          <col />
          <col />
        </colgroup>
        <thead>
          <tr>
            <th scope="col">NO</th>
            <th scope="col">프로젝트 일자</th>
            <th scope="col">프로젝트명</th>
            <th scope="col">노출여부</th>
            <th scope="col">분류1</th>
            <th scope="col">분류2</th>
            <th scope="col">분류3</th>
            <th scope="col">문의일시</th>
            <th scope="col">수정일시</th>
          </tr>
        </thead>
        <tbody>
          <!-- 데이터가 있을 경우// -->
          <tr v-for="(item, idx) in list" :key="item.portfolio_id">
            <td>{{ pageInfo.totalNum - (idx + no) }}</td>
            <td>{{ item.project_date }}</td>
            <td>
              <a
                href="javascript:;"
                @click="$router.push({ path: `/admin/portfolioDetail/${item.portfolio_id}` })"
                class="col_blue bold"
                >{{ item.project_name }}</a
              >
            </td>
            <td :class="item.is_use === 'N' ? 'col_red' : 'col_gr'">{{ item.is_use }}</td>
            <td>{{ item.tag1 }}</td>
            <td>{{ item.tag2 }}</td>
            <td>{{ item.tag3 }}</td>
            <td>{{ changeDateTime(item.reg_date) }}</td>
            <td>{{ changeDateTime(item.update_date) }}</td>
          </tr>
          <!-- //데이터가 있을 경우 -->

          <!-- 데이터가 없을 경우// -->
          <tr v-if="!list.length">
            <td colspan="9" class="no_data">등록된 내용이 없습니다.</td>
          </tr>
          <!-- //데이터가 없을 경우 -->
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { datetimeToString } from "@/util/datetime";

export default {
  name: "PortfolioList",
  props: {
    list: {
      type: Array,
      default: () => []
    },
    pageInfo: {
      type: Object,
      default: () => ({ totalNum: 0, pageNum: 1 })
    }
  },
  computed: {
    no() {
      if(this.pageInfo.pageNum > 1){
        return 12 * (this.pageInfo.pageNum - 1);
      }
      return 0
    }
  },
  methods: {
    changeDateTime(date) {
      if (date) {
        const parsingData = new Date(date);
        return datetimeToString(parsingData);
      }
      return "";
    },
    movePage(path) {
      if (this.$route.path !== path) {
        this.$router.push(path);
      }
    }
  }
};
</script>

<style></style>
