<template>
  <div class="main_slider main-bg">
    <div class="swiper-container main_swiper">
      <div class="swiper-wrapper">
        <Swiper
          ref="mainSwiper"
          :options="swiperOptions"
        >
          <SwiperSlide class="swiper-slide">
            <div class="slide-inner">
              <p>
                <em>Future Your Business</em>
                <strong class="col_em">in the CLOUD</strong>
              </p>
              <p>
                <span>클라우드 시스템 개발 & 운영의 고민</span><br />
                엔더스의 전문적인 노하우와 클라우드가 만나 기업 혁신을 이끕니다.
              </p>
            </div>
            <div class="slide_bg bg1"></div>
          </SwiperSlide>

          <SwiperSlide class="swiper-slide">
            <div class="slide-inner">
              <p>
                <em>새로운 디지털 바람에</em>
                <strong>
                  <span class="col_em">성공의 돛</span>
                  <span class="txt_emph">을 달아드립니다.</span>
                </strong>
              </p>
              <p>
                Post-Untact 시대, 엔더스 E-BIZ 서비스로 효율적인 비즈니스 목적을 달성하세요.
              </p>
            </div>
            <div class="slide_bg bg2"></div>
          </SwiperSlide>

          <SwiperSlide class="swiper-slide">
            <div class="slide-inner">
              <p>
                <em>E-BIZ 서비스 혁신을 위한</em>
                <strong>
                  <span class="col_em">전문 파트너</span>
                  <span class="txt_emph">가 필요한가요?</span>
                </strong>
              </p>
              <p>
                <span class="mb_block">비즈니스 분석력과 기술력을 겸비한</span> 전문가 그룹과 최적의
                방안을 찾아보세요!
              </p>
            </div>
            <div class="slide_bg bg3"></div>
          </SwiperSlide>
          <div class="swiper-pagination main_pagination" slot="pagination" @click="swiperStatus = ''"></div>
        </Swiper>
        <div class="play_area">
          <button class="btn_play_control" :class="swiperStatus" @click="autoPlay">
            <span class="hidden">자동재생 정지</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MainSwiper',
  data() {
    return {
      swiperOptions: {
        effect: 'fade',
        loop: true,
        speed: 1500,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        fadeEffect: {
          crossFade: true,
        },
        autoplay: {
          delay: 4000,
        },
        a11y: {
          paginationBulletMessage: '슬라이드  {{index}}번 보기',
        },
      },
      swiperStatus: 'active',
    };
  },
  computed: {
    swiper() {
      return this.$refs.mainSwiper.$swiper;
    },
  },
  methods: {
    autoPlay() {
      if (this.swiperStatus) {
        this.swiper.autoplay.stop();
        this.swiperStatus = '';
      } else {
        this.swiper.autoplay.start();
        this.swiperStatus = 'active';
      }
    },
  },
};
</script>
