import { http } from '@/util/http';

async function applicantPost(data) {
  const form = new FormData();
  form.append('resume', data.resume.file);
  if (data.portfolio.length > 0) {
    data.portfolio.forEach((n, idx) => {
      form.append(`portfolio${idx + 1}`, n.file);
    });
  }
  form.append('recruitType', data.recruitType);
  form.append('name', data.name);
  form.append('phone', data.phone);
  form.append('mail', data.mail);
  try {
    const result = await http.post('/enders/api/applicant', form);
    return result;
  } catch {
    return {
      code: 500,
    };
  }
}

export default {
  applicantPost,
};
