<template>
  <div id="content" class="dcx">
      <!-- cont_body// -->
      <section class="cont_body">

          <!-- sub_banner// -->
          <div class="sub_banner">
              <div class="inner">
                  <div class="txt_area">
                      <p class="small_txt">Digital Consumer eXperience</p>
                      <p class="main_txt">Best UIUX Design for DCX Optimization</p>
                  </div>
                  <div class="bnnr_img"><img src="@/assets/img/sub/dcx-test.png" alt="uiux"></div>
              </div>
          </div>
          <!-- //sub_banner -->

          <h2 class="h2_sub inner">
              <span class="gray_txt">디지털 환경 전반에서 고객의 행동 지식 경험을 찾아 분석하고 반영합니다.</span>
              고객과 연결 되는 새로운 방식의 UIUX 구현
          </h2>
          <!-- work_prc// -->
          <div class="work_prc">
              <div class="inner">
                  <h3>
                      <strong>Work Process</strong>
                      <span>엔더스만의 디지털 고객경험관리 방식</span>
                  </h3>
                  <div class="work_stepwrap">
                      <ol>
                          <li>
                              <div class="icon_circle"><img src="@/assets/img/sub/dcx/workprc_dcx_01.png" alt="디지털전략분석"></div>
                              <div class="work_desc">
                                  <strong>
                                      <span class="txt_step">STEP 01</span>
                                      Define Digital Strategy
                                  </strong>
                                  <p>
                                      사업 전략 수립만을 위한 디지털 경험 분석이
                                      아닌 전사의 모든 디지털 서비스 현황과
                                      디지털 고객 경험을 분석하여<br>
                                      최상의 맞춤 전략을 도출합니다.
                                  </p>
                              </div>
                          </li>
                          <li>
                              <div class="icon_circle"><img src="@/assets/img/sub/dcx/workprc_dcx_02.png" alt="개발플랜제공"></div>
                              <div class="work_desc">
                                  <strong>
                                      <span class="txt_step">STEP 02</span>
                                      Build Plan
                                  </strong>
                                  <p>
                                      개발 환경 선정부터 서비스 운영 방안,<br>
                                      시스템 도입까지 모두 고려한 디지털
                                      트랜스포메이션의 토탈 플랜을 제공합니다.
                                  </p>
                              </div>
                          </li>
                          <li>
                              <div class="icon_circle"><img src="@/assets/img/sub/dcx/workprc_dcx_03.png" alt="ui ux 디자인"></div>
                              <div class="work_desc">
                                  <strong>
                                      <span class="txt_step">STEP 03</span>
                                      UIUX Design
                                  </strong>
                                  <p>
                                      기업 전략에 최적화된 UI/UX 설계를 바탕으로<br>
                                      최신 트렌드와 사용성에 기반한
                                      Visual Design을 진행하고 협업하여<br>
                                      스타일을 제작합니다.
                                  </p>
                              </div>
                          </li>
                      </ol>
                  </div>
              </div>
          </div>
          <!-- //work_prc -->

          <div class="visual_dcx">
              <div class="inner">
                  <h3>
                      <span class="gray_txt">Post-정보화 시대, Post-비대면 시대<br> 세상에 수억만 개의 IT 서비스가 제작되고 사라져 갑니다.</span>
                      <em>엔더스 DCX 사업부</em>는 혁신적인 디지털 고객경험 파트너로서,
                      10년 후에 돌아보아도 최선의 선택만 남는 서비스를 만들겠습니다.
                  </h3>

                  <div class="dcx_desc">
                      <div class="type_pc">
                          <img src="@/assets/img/sub/dcx/dcx_info.png" alt="ui ux cx 설명">
                      </div>
                      <div class="type_mb">
                          <div><img src="@/assets/img/sub/dcx/desc_dcx.png" alt="ui ux cx 설명"></div>
                          <div>
                              <strong>Digital Customer eXperience란?</strong>
                              <span>디지털 고객경험은 기업과 접하는 모든 고객 여정과 행동 및 감정 등 요인을 이해하는 방식입니다.</span>
                              <span>
                                    비대면 서비스가 확대되면서 디지털 고객 경험은 제조 , 유통 , 금융을 포함한 모든 사업 분야의 성공의 필수 요인이 되었습니다.
                                    엔더스는 고객이 기업과 거래 , 소통하는 모든 단계에서 긍정적 경험을 지속할수 있도록 총체적 경험 관리를 고객경험을 통합적으로
                                    분석하여 서비스를 설계하고 디자인합니다.
                              </span>
                          </div>
                      </div>
                  </div>
              </div>
          </div>

          <!-- sub_common// -->
            <PortfolioBanner />
          <!-- //sub_common -->
          <!-- 문의상담영역// -->
            <ConsultBanner />

          <!-- //문의상담영역 -->
      </section>
  </div>
</template>
<script>
import ConsultBanner from '@/components/ConsultBanner.vue';
import PortfolioBanner from '@/components/PortfolioBanner.vue';

export default {
  // name은 파일 이름을 쓰시면 됩니다. ex) Portfolio.vue => Portfolio
  name: 'Dcx',
  // components 위에서 임포트한 컴포넌트를 여기에 등록하고 위에 HTML영역에 태그로 사용가능합니다.
  components: {
    ConsultBanner,
    PortfolioBanner,
  },
};
</script>
