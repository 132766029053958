<template>
  <div class="list_item">
    <div class="item_desc">
      <dl>
        <dt>
          <span>{{pcItem.subTitle}}</span>
          <strong>{{pcItem.title}}</strong>
        </dt>
        <dd>
          {{pcItem.content}}
        </dd>
      </dl>
      <div class="srv_img"><img :src="pcItem.img"/></div>
    </div>
    <!-- //item_desc -->

    <!-- item_menu// -->
    <dl class="item_menu">
      <dt>{{pcItem.f1Title}}</dt>
      <dd>{{pcItem.f1Content}}</dd>

      <dt>{{pcItem.f2Title}}</dt>
      <dd>{{pcItem.f2Content}}</dd>

      <dt>{{pcItem.f3Title}}</dt>
      <dd>{{pcItem.f3Content}}</dd>
    </dl>
  </div>
</template>

<script>
export default {
  name: 'SeviceInfoPcItem',
  props: {
    pcItem: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
