<template>
  <dl>
      <dt>
          <strong>
              [필수] 개인정보 수집 및 이용 동의
          </strong>
          <span class="desc">
              엔더스는 아래의 목적으로 개인정보를 수집 및 이용하며, 개인정보 유출방지를 위해 최선을 다하겠습니다.
          </span>
      </dt>
      <dd>
          <!-- policy_cont// -->
          <div class="policy_cont">
              <ol>
                  <li>
                      1. 수집하는 개인정보의 항목
                      <p>1) 필수항목 : 서명, 연락처, 이메일, 상담내용</p>
                  </li>
                  <li>
                      2. 개인정보의 수집 및 이용 목적
                      <p>1) 상담 및 컨설팅 운영, 귀사 및 제휴업체의 재화 또는 서비스, 콘텐츠 제공 등 맞춤 서비스 제공 목적</p>
                      <p>2) 프로젝트 수행을 위한 정보자료로 활용</p>
                      <p>3) 민원 사무 처리: 서비스 제공에 관한 계약 이행 및 불만 처리 등 민원 처리, 고지 사항 전달, 분쟁 조정을 위한 기록</p>
                  </li>
                  <li>
                      3. 개인정보 파기 시기
                      <p>1) 사업을 폐업하는 경우</p>
                      <p>2) 이용자가 동의를 철회, 거부한 경우</p>
                  </li>
              </ol>
          </div>
          <!-- //policy_cont -->
      </dd>
  </dl>
</template>

<script>
export default {
  name: 'Agreement',
};
</script>

<style></style>
