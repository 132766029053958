<template>
  <div class="graybox">
    <p class="required">*필수기재 항목</p>
    <div class="list-area">
      <ul>
        <li>
          <label class="required">프로젝트 일자</label>
          <div class="list-item">
            <div class="datepicker">
              <input type="date" id="start" name="datepicker" v-model="updateInfo.project_date" />
            </div>
          </div>
        </li>
        <li>
          <label class="required">노출여부</label>
          <div class="list-item">
            <!-- 라디오버튼 -->
            <label for="rdo_01">
              <input
                type="radio"
                id="rdo_01"
                name="chk_condition"
                v-model="updateInfo.is_use"
                value="Y"
              /><span class="col_gr">Y</span>
            </label>
            <label for="rdo_02">
              <input
                type="radio"
                id="rdo_02"
                name="chk_condition"
                v-model="updateInfo.is_use"
                value="N"
              /><span class="col_red" style="margin-right:0;">N</span>
            </label>
          </div>
        </li>
        <li class="col-full">
          <label class="required">프로젝트명</label>
          <div class="list-item">
            <input type="text" placeholder="" v-model="updateInfo.project_name" />
          </div>
        </li>
        <li class="col-full">
          <label class="required">분류1</label>
          <div class="list-item">
            <div class="select">
              <select title="옵션 선택" v-model="updateInfo.tag1">
                <option value="Mobile app">Mobile app</option>
                <option value="App">App</option>
                <option value="Web">Web</option>
                <option value="Web App">Web App</option>
              </select>
            </div>
          </div>
        </li>
        <li class="col-full">
          <label>분류2</label>
          <div class="list-item">
            <div class="select">
              <select title="옵션 선택" v-model="updateInfo.tag2">
                <option value="Cloud">Cloud</option>
                <option value="UMS">UMS</option>
                <option value="EIS">EIS</option>
                <option value="Serverless">Serverless</option>
                <option value="UIUX">UIUX</option>
                <option value="반응형">반응형</option>
                <option value="E-BIZ">E-BIZ</option>
                <option value="빅데이터">빅데이터</option>
              </select>
            </div>
          </div>
        </li>
        <li class="col-full">
          <label>분류3</label>
          <div class="list-item">
            <div class="select">
              <select title="옵션 선택" v-model="updateInfo.tag3">
                <option value="Cloud">Cloud</option>
                <option value="UMS">UMS</option>
                <option value="EIS">EIS</option>
                <option value="Serverless">Serverless</option>
                <option value="UIUX">UIUX</option>
                <option value="반응형">반응형</option>
                <option value="E-BIZ">E-BIZ</option>
                <option value="빅데이터">빅데이터</option>
              </select>
            </div>
          </div>
        </li>
        <li class="col-full">
          <label>제작목적</label>
          <div class="list-item">
            <textarea placeholder="" v-model="updateInfo.purpose"></textarea>
          </div>
        </li>
        <li class="col-full">
          <label>기획의도</label>
          <div class="list-item">
            <textarea placeholder="" v-model="updateInfo.intention"></textarea>
          </div>
        </li>
        <li class="col-full">
          <label>특징</label>
          <div class="list-item">
            <textarea placeholder="" v-model="updateInfo.feature"></textarea>
          </div>
        </li>

        <li class="col-full">
          <label class="required">대표 이미지</label>
          <div class="list-item">
            <!-- 파일첨부 시 노출 최대 1개까지 -->
            <div class="state_added" v-if="main_img_max">
              <div class="data_box">
                <!-- 이미지영역// -->
                <div class="img_area" v-if="!updateInfo.main_img">
                  <img :src="`/files/portfolio/${detail.main_img}`" />
                </div>
                <div class="img_area" v-else>
                  <img :src="updateInfo.main_img_url" />
                </div>
                <!-- 이미지영역// -->
                <div class="data_info">
                  <strong>{{
                    !updateInfo.main_img ? detail.main_img : updateInfo.main_img_title
                  }}</strong>
                </div>
              </div>
              <button class="btn" @click.prevent="deleteMainImage">삭제</button>
            </div>
            <label for="thumb" class="attach" v-else>
              파일첨부
              <input type="file" id="thumb" hidden @change="inputMainImage($event)" />
            </label>
          </div>
        </li>

        <li class="col-full">
          <label>부가 이미지</label>
          <div class="list-item">
            <!-- 파일첨부 시 노출  최대 3개-->
            <div class="state_added" v-for="(sub_img, idx) in detail.sub_imgs" :key="idx">
              <div class="data_box">
                <div class="img_area">
                  <img :src="`/files/portfolio/${detail.sub_imgs[idx]}`" />
                </div>
                <div class="data_info">
                  <strong>{{ detail.sub_imgs[idx] }}</strong>
                  <span> MB</span>
                </div>
              </div>
              <button class="btn" @click.prevent="deleteDetailSubImages(idx)">삭제</button>
            </div>

            <div class="state_added" v-for="(sub_img, idx) in updateInfo.sub_imgs" :key="'a' + idx">
              <div class="data_box">
                <div class="img_area">
                  <img :src="sub_img.url" />
                </div>
                <div class="data_info">
                  <strong>{{ sub_img.title }}</strong>
                  <span>{{ Math.floor(sub_img.size / 1024) }} MB</span>
                </div>
              </div>
              <button class="btn" @click.prevent="deleteSubImages(idx)">삭제</button>
            </div>
            <!-- 파일첨부 시 노출 -->
            <label for="thumb" class="attach" v-if="!imgs_max">
              파일첨부
              <input type="file" id="thumb" hidden @change="inputSubImages($event)" multiple />
            </label>
          </div>
        </li>

        <li class="col-full">
          <label>등록 일시</label>
          <div class="list-item">
            <p class="inline-txt">{{ changeDateTime(detail.reg_date) }}</p>
          </div>
        </li>
        <li class="col-full">
          <label>수정 일시</label>
          <div class="list-item">
            <p class="inline-txt">{{ changeDateTime(detail.update_date) }}</p>
          </div>
        </li>
      </ul>
    </div>
    <ListBtn />
    <div class="btn-wrap">
      <button type="button" class="btn big" @click="deletePortfolio()">삭제</button>
      <button type="button" class="btn big fullblue" @click="updatePortfolio(updateInfo)">
        저장
      </button>
    </div>
  </div>
</template>

<script>
import ListBtn from "@/components/admin/ListBtn.vue";
import portfolioApi from "@/apis/portfolio";
import { datetimeToString } from "@/util/datetime";
import moment from "moment";

export default {
  name: "EditContent",
  components: {
    ListBtn
  },
  mounted() {
    this.init();
  },
  data() {
    return {
      detail: {},
      updateInfo: {
        project_date: moment().format("YYYY-MM-DD"),
        project_name: "",
        tag1: "",
        tag2: "",
        tag3: "",
        purpose: "",
        intention: "",
        is_use: "",
        feature: "",
        main_img: "",
        main_img_url: "",
        sub_imgs: [],
        sub_imgs_max: false,
        sub_imgs_delete_info: {
          names: [],
          deleted: []
        }
      },
      main_img_max: false,
      imgs_max: false
    };
  },
  watch: {
    detail() {
      this.updateInfo.project_date = this.detail.project_date;
      this.updateInfo.project_name = this.detail.project_name;
      this.updateInfo.tag1 = this.detail.tag1;
      this.updateInfo.tag2 = this.detail.tag2;
      this.updateInfo.tag3 = this.detail.tag3;
      this.updateInfo.purpose = this.detail.purpose;
      this.updateInfo.intention = this.detail.intention;
      this.updateInfo.is_use = this.detail.is_use;
      this.updateInfo.feature = this.detail.feature;
      for (let index = 0; index < this.detail.sub_imgs.length; index += 1) {
        this.updateInfo.sub_imgs_delete_info.names.push(`sub${index + 1}D`);
      }
    }
  },
  methods: {
    async init() {
      const result = await portfolioApi.getPortfolioDetail(this.$route.params.id);
      if (result.code === 500) {
        await this.$alert("데이터 송신 중 오류가 발생했습니다.", "", "error");
        this.$router.push("/admin/portfolioManagement");
        return;
      }
      this.detail = result.result;
      if (this.detail.sub_imgs.length === 3) {
        this.imgs_max = true;
      }
      if (this.detail.main_img) {
        this.main_img_max = true;
      }
    },
    async deletePortfolio() {
      this.$confirm("삭제된 정보는 복구가 불가합니다. 정말 삭제하겠습니까?", "", "warning")
        .then(async () => {
          const result = await portfolioApi.deletePortfolioDetail(this.$route.params.id);
          if (result.code === 500) {
            await this.$alert("데이터 삭제 중 오류가 발생했습니다.", "", "error");
            return;
          }
          await this.$alert("삭제 되었습니다.", "", "success");
          this.$router.push("/admin/portfolioManagement");
        })
        .catch(() => {});
    },
    async updatePortfolio(data) {
      this.$confirm("입력 정보를 업데이트 하시겠습니까?", "", "question")
        .then(async () => {
          const result = await portfolioApi.updatePortfolioDetail(this.$route.params.id, data);
          console.log(result);
          if (result.code === 500) {
            await this.$alert("데이터 업데이트 중 오류가 발생했습니다.", "", "error");
            return;
          }
          await this.$alert("업데이트 되었습니다.", "", "success");
          this.$router.push("/admin/portfolioManagement");
        })
        .catch(() => {});
    },
    changeDateTime(date) {
      if (date) {
        const parsingData = new Date(date);
        return datetimeToString(parsingData);
      }
      return "";
    },
    inputMainImage(event) {
      console.dir(event.target);
      if (event.target.files.length > 0) {
        this.updateInfo.main_img_title = event.target.files[0].name;
        this.updateInfo.main_img_size = event.target.files[0].size;
        // eslint-disable-next-line prefer-destructuring
        this.updateInfo.main_img = event.target.files[0];
        this.updateInfo.main_img_url = URL.createObjectURL(event.target.files[0]);
        this.main_img_max = true;
      }
    },
    inputSubImages(event) {
      if (event.target.files.length > 0) {
        const inputFile = event.target.files[0];
        this.updateInfo.sub_imgs.push({
          title: inputFile.name,
          size: inputFile.size,
          file: inputFile,
          url: URL.createObjectURL(inputFile)
        });
        if (this.updateInfo.sub_imgs.length + this.detail.sub_imgs.length === 3) {
          this.imgs_max = true;
        }
      }
    },
    deleteMainImage() {
      this.detail.main_img = "";
      this.main_img_max = false;
    },
    deleteSubImages(idx) {
      this.updateInfo.sub_imgs.splice(idx, 1);
      this.updateInfo.sub_imgs_max = false;
      this.imgs_max = false;
    },
    deleteDetailSubImages(idx) {
      this.detail.sub_imgs.splice(idx, 1);
      const del = this.updateInfo.sub_imgs_delete_info.names.splice(idx, 1);
      this.updateInfo.sub_imgs_delete_info.deleted = this.updateInfo.sub_imgs_delete_info.deleted.concat(
        del
      );
      this.imgs_max = false;
    }
  }
};
</script>

<style></style>
