<template>
  <div class="graybox">
    <div class="list-area">
      <ul>
        <li>
          <label>지원 일자</label>
          <div class="list-item">
            <div class="datescope">
              <input type="date" id="start" name="datepicker" v-model="searchForm.startDate">
            </div>
            <span class="hyppen date"></span>
            <div class="datescope">
              <input type="date" id="end" name="datepicker" v-model="searchForm.endDate">
            </div>
          </div>
        </li>
        <li>
          <label>지원자명</label>
          <div class="list-item">
            <input type="text" placeholder="문의자명을 입력해주세요." v-model="searchForm.name">
          </div>
        </li>
        <li>
          <label>지원 분야</label>
          <div class="list-item">
            <div class="select">
              <select title="옵션 선택" v-model="searchForm.applyType">
                <option value="">선택</option>
                <option value="프론트엔드 개발자">프론트엔드 개발자</option>
                <option value="백엔드 개발자">백엔드 개발자</option>
                <option value="JAVA 개발자">JAVA 개발자</option>
                <option value="디자이너">디자이너</option>
                <option value="SI사업 PM">SI사업 PM</option>
                <option value="웹기획자">웹기획자</option>
              </select>
            </div>
          </div>
        </li>
        <li>
          <label>진행 상황</label>
          <div class="list-item">
            <div class="select">
              <select title="옵션 선택" v-model="searchForm.status">
                <option value="">선택</option>
                <option value="서류심사 요청">서류심사 요청</option>
                <option value="서류심사 검토">서류심사 검토</option>
                <option value="실무면접 완료">실무면접 완료</option>
                <option value="임원면접 완료">임원면접 완료</option>
                <option value="스팸문의">스팸문의</option>
              </select>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <SearchBtn @search-data="searchData" />
  </div>
</template>

<script>
import SearchBtn from '@/components/admin/SearchBtn.vue';
import moment from 'moment'

export default {
  name: 'ApplicantSearch',
  components:{
    SearchBtn,
  },
  data() {
    return {
      searchForm: {
        name: '',
        applyType: '',
        startDate: moment().format('YYYY-01-01'),
        endDate: moment().format('YYYY-MM-DD'),
        status: ''
      }
    }
  },
  methods: {
    searchData() {
      this.$emit('search-data', this.searchForm)
    }
  },
};
</script>

<style></style>
