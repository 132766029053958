import Axios from "axios";
import store from "@/store";

// const baseUrl = process.env.VUE_APP_API_BASE_URL;
const defaultHeaders = {
  "Content-Type": "application/json;charset=UTF-8"
  // accessToken: process.env.VUE_APP_ACCESSTOKEN  access token 불필요로 인한 주석처리 230418 엄채림
};

const instance = Axios.create({
  timeout: 3000
});
instance.interceptors.request.use(
  config => {
    store.dispatch("showSpinner");
    return config;
  },
  error => {
    console.log(error);
    return Promise.reject(error);
  }
);
instance.interceptors.response.use(
  response => {
    console.log("response", response);
    store.dispatch("hideSpinner");
    return response;
  },
  error => {
    console.log("error", error);
    store.dispatch("hideSpinner");
    return Promise.reject(error);
  }
);

// 비동기 통신 Object 정의
const httpInstance = {
  get(uri, params = {}, headers = {}, additionalConfig = {}) {
    const config = {
      headers: {
        ...defaultHeaders,
        ...headers
      },
      ...additionalConfig,
      params
    };

    const promise = instance.get(uri, config);
    return promise.then(({ data }) => data);
  },
  patch(uri, params = {}, headers = {}, additionalConfig = {}) {
    const config = {
      headers: {
        ...defaultHeaders,
        ...headers
      },
      ...additionalConfig
    };

    const promise = instance.patch(uri, params, config);
    return promise.then(({ data }) => data);
  },
  put(uri, params = {}, headers = {}, additionalConfig = {}) {
    const config = {
      headers: {
        ...defaultHeaders,
        ...headers
      },
      ...additionalConfig
    };

    const promise = instance.put(uri, params, config);
    return promise.then(({ data }) => data);
  },
  post(uri, params = {}, headers = {}, additionalConfig = {}) {
    const config = {
      headers: {
        ...defaultHeaders,
        ...headers
      },
      ...additionalConfig
    };

    const promise = instance.post(uri, params, config);
    return promise.then(({ data }) => data);
  },
  delete(uri, params = {}, headers = {}) {
    const promise = instance.delete(uri, {
      headers: {
        ...defaultHeaders,
        ...headers
      },
      data: {
        ...params
      }
    });
    return promise.then(({ data }) => data);
  }
};

export const axios = instance;
export const http = httpInstance;
