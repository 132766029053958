import { http } from '@/util/http';
// url 바뀔 예정
async function getConsult(data) {
  try {
    const result = await http.get('/enders/api/consult', data);
    return result;
  } catch {
    return {
      code: 500,
    };
  }
}

async function getConsultDetail(id) {
  try {
    const result = await http.get(`/enders/api/consult/${id}`);
    return result;
  } catch {
    return {
      code: 500,
    };
  }
}

async function deleteConsultDetail(id) {
  try {
    const result = await http.delete(`/enders/api/consult/${id}`);
    return result;
  } catch {
    return {
      code: 500,
    };
  }
}

async function updateConsultDetail(id, data) {
  try {
    const result = await http.put(`/enders/api/consult/${id}`, data);
    return result;
  } catch {
    return {
      code: 500,
    };
  }
}

export default {
  getConsult,
  getConsultDetail,
  deleteConsultDetail,
  updateConsultDetail
};
