<template>
  <div class="btn-wrap">
    <button type="button" class="btn fullblue" @click="searchData">검색</button>
  </div>
</template>

<script>
export default {
  name: 'SearchBtn',
  methods:{
    searchData() {
      this.$emit('search-data')
    }
  }
};
</script>

<style></style>
