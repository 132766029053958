<template>
  <div class="area_partner">
    <div class="partner_list">
      <!-- v-for="사용할 이름 in 데이터가 들어있는 변수" :key는 아이디를 주면 됩니다.-->
      <div v-for="(item, idx) in datas" :key="idx">
        <div class="thumb_pf">
          <img :src="`/files/portfolio/${item.main_img}`" />
        </div>
        <dl class="partner_desc">
          <dt>
            <strong>{{ item.project_name }}</strong>
          </dt>
          <dd>
            <span v-if="item.tag1">{{ item.tag1 }}</span>
            <span v-if="item.tag2">{{ item.tag2 }}</span>
            <span v-if="item.tag3">{{ item.tag3 }}</span>
          </dd>
        </dl>
      </div>
    </div>
    <!-- @click 클릭 했을 때 $emit은 자식이 부모 컴포넌트에 특정 신호를 줄 때 사용합니다.
    여기서는 신호를 줘서 부모에서 데이터 처리를 하게 했습니다. -->
    <div class="area_btn" @click="$emit('more-datas')" v-if="isMoreBtn">
      <button class="btn_plus">
        <span>더보기</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Content',
  // props는 부모 컴포넌트에서 자식 컴포넌트로 데이터를 넘길 때 사용하는 것입니다.
  props: {
    datas: {
      type: Array,
      default: () => [],
    },
    isMoreBtn: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style></style>
