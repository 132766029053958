<template>
  <div class="service_area srvc_solution">
    <div class="inner">
      <h3 class="h3_ttl">AXon EIS 주요기능</h3>
      <!-- service_list// -->
      <div class="service_list type_pc">
        <SeviceInfoItem v-for="(item, idx) in serviceItem" :key="idx" :pcItem="item"/>
      </div>
      <!-- //type_pc -->

      <!-- service_list// -->
      <div class="service_list type_mb">
        <Swiper :options="swiperOptions">
          <SwiperSlide class="swiper-slide" v-for="(item, idx) in serviceItem" :key="idx" >
            <SeviceInfoItem :pcItem="item"/>
          </SwiperSlide>
          <div class="swiper-pagination mb_srv_pager swiper-pagination-bullets" slot="pagination"></div>
        </Swiper>
      </div>
      <!-- //type_mb-->
      <!-- //service_list -->
    </div>
  </div>
</template>

<script>
import SeviceInfoItem from './SeviceInfoItem.vue';

export default {
  name: 'ServiceInfo',
  components: {
    SeviceInfoItem,
  },
  data() {
    return {
      swiperOptions: {
        spaceBetween: 30,
        pagination: {
          el: '.swiper-pagination',
        },
      },
      serviceItem: [
        {
          img: require('@/assets/img/sub/axon/eis_01.png'),
          subTitle: '사용자 환경',
          title: '보고서 분석 및 활용',
          content:
            '대시보드의 개인화 , 즐겨 찾는 보고서의 활용 편의성, 분석 보고서의 개인 맞춤형 관리 체계 등 개인화 기능을 통한 시스템 분석의 효율과 업무 Insight 지원을 위한 분석 환경 제공',
          f1Title: 'Quick KPI',
          f1Content: '업무 및 분석 목적에 맞춰 빠르게 KPI 대시보드를 커스텀 하는 핵심 성과 지표 모니터링',
          f2Title: '마이홈',
          f2Content: '즐겨 찾는 보고서와 외부 정보를 Link 하여 업무 효율 증대를 지원하는 대시보드 기능',
          f3Title: '즐겨찾기',
          f3Content: '업무 효율에 따라 공용 폴더 체계를 커스텀 하는 개인화 즐겨찾기 기능',
        },
        {
          img: require('@/assets/img/sub/axon/eis_02.png'),
          subTitle: '관리자 환경',
          title: '사용자 운영 관리',
          content:
            '시스템 내, 외부에서 엑셀로 관리되는 데이터를 사용자가 운영하고 분석 결과를 공유하는 양방향 커뮤니케이션 기능을 제공',
          f1Title: '엑셀 업로드',
          f1Content: '외부 및 개인화된 엑셀 데이터를 업로드 하여 분석 보고서로 즉시 활용',
          f2Title: '보고서 메모',
          f2Content: '보고서 이슈 및 현황 작성과 공개여부를 통한 소통, 메모 히스토리 추적 기능',
          f3Title: '보고서 게시판',
          f3Content: '전사 관점의 실적 관리 체계 공유부터 분석결과 이슈 공유, Snap 관리를 모두 지원',
        },
        {
          img: require('@/assets/img/sub/axon/eis_03.png'),
          subTitle: '개발 환경',
          title: '강렬한 시각화',
          content:
            'High charts의 기본 컴포넌트를 적용한 강력한 시각화 기능을 통해 정보의 Insight를 제공하고 High charts의 Advance기능을 Add-in하여 더욱 풍부한 시각화를 제공',
          f1Title: '풍부한 시각화',
          f1Content: 'Industry에 특화된 다양한 차트 패치',
          f2Title: '안정적인 엔진',
          f2Content: 'High charts 엔진을 내장한 안정적인 시각화 엔진 제공',
          f3Title: '',
          f3Content: '',
        },
        {
          img: require('@/assets/img/sub/axon/eis_04.png'),
          subTitle: '개발 환경',
          title: '혁신적인 아키텍처',
          content:
            '효과적인 개발 및 유지관리를 위한 도구와 고가용성을 보장하는 효율적인 제품 엔진으로 구성',
          f1Title: '개발 편의성',
          f1Content: '개발 방식의 효율성을 높이기 위한 WYSIWYG 방법론을 적용',
          f2Title: '효율적인 엔진',
          f2Content: '서버 및 Session Cache를 활용한 데이터 엔진 구성과 다양한 디바이스 지원을 위한 HTML5 기반 효율적인 UI 엔진',
          f3Title: '',
          f3Content: '',
        },
      ],
    };
  },
};
</script>
