<template>
  <div id="content">
    <section class="cont_head">
      <AdminPageTitle title="연혁 수정" subTitle="" />
    </section>

    <section class="cont_body">
      <fieldset>
        <legend>조건 및 추출조건 선택</legend>
        <EditContent />
      </fieldset>
    </section>
  </div>
</template>

<script>
import AdminPageTitle from "@/components/admin/AdminPageTitle.vue";
import EditContent from "./compo/EditContent.vue";

export default {
  // name은 파일 이름을 쓰시면 됩니다. ex) Portfolio.vue => Portfolio
  name: "HistoryDetail",
  // components 위에서 임포트한 컴포넌트를 여기에 등록하고 위에 HTML영역에 태그로 사용가능합니다.
  components: {
    AdminPageTitle,
    EditContent
  }
};
</script>
