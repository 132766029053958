import Vue from "vue";
import VueGtag from "vue-gtag";
import VueAwesomeSwiper from "vue-awesome-swiper";
import VueSimpleAlert from "vue-simple-alert";
import VueCookies from "vue-cookies";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "swiper/css/swiper.css";

Vue.use(VueCookies);
Vue.use(VueSimpleAlert);
Vue.use(VueAwesomeSwiper /* { default options with global component } */);

Vue.use(VueGtag, {
  config: {
    id: "G-F1L1HVRETB" // [Google Analytics] Tracking ID
  }
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  watch: {
    $route(to) {
      document.title = to.meta.title;
    }
  },
  render: h => h(App)
}).$mount("#app");
