<template>
  <div class="history_area">
    <div class="inner">
      <h3 class="h3_ttl">
        <span>
          2013년도 설립부터
        </span>
        멈추지 않고 달려왔습니다
      </h3>
    </div>

    <div class="history_slider">
      <div class="history_item">
        <Swiper ref="historySwiper" :options="swiperOptions">
          <SwiperSlide class="swiper-slide" v-for="(item, idx) in historyInfo" :key="idx">
            <div class="area_year">
              <em>{{ item.year }}</em>
              <span class="red_dot"></span>
            </div>
            <div class="area_desc gray_txt">
              <p v-for="(content, idx) in item.content" :key="idx">{{ content }}</p>
            </div>
          </SwiperSlide>
        </Swiper>
        <div class="area_btn">
          <div class="swiper-button-prev btn_prev disabled" slot="button-prev"></div>
          <div class="swiper-button-next btn_next" slot="button-next"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import historyApi from "@/apis/history";

export default {
  name: "HistorySwiper",
  mounted() {
    this.init();
  },
  data() {
    return {
      swiperOptions: {
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        },
        slidesPerView: 6,
      },
      historyInfo: []
    };
  },
  computed: {
    swiper() {
      return this.$refs.historySwiper.$swiper;
    }
  },
  methods: {
    async init() {
      const result = await historyApi.getForntHistory();
      this.historyInfo = result.result;
    }
  }
};
</script>
