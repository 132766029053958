import { http } from '@/util/http';
// url 바뀔 예정
async function getHistory(data) {
  try {
    const result = await http.get('/enders/api/history', data);
    return result;
  } catch {
    return {
      code: 500,
    };
  }
}

async function getForntHistory(data) {
  try {
    const result = await http.get('/enders/api/front/history', data);
    return result;
  } catch {
    return {
      code: 500,
    };
  }
}

async function getHistoryDetail(id) {
  try {
    const result = await http.get(`/enders/api/history/${id}`);
    return result;
  } catch {
    return {
      code: 500,
    };
  }
}

async function deleteHistoryDetail(id) {
  try {
    const result = await http.delete(`/enders/api/history/${id}`);
    return result;
  } catch {
    return {
      code: 500,
    };
  }
}

async function createHistoryDetail(data) {
  try {
    const result = await http.post('/enders/api/history', data);
    return result;
  } catch {
    return {
      code: 500,
    };
  }
}

async function updateHistoryDetail(id, data) {
  try {
    const result = await http.put(`/enders/api/history/${id}`, data);
    return result;
  } catch {
    return {
      code: 500,
    };
  }
}

export default {
  getHistory,
  getHistoryDetail,
  createHistoryDetail,
  deleteHistoryDetail,
  updateHistoryDetail,
  getForntHistory
};
