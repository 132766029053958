<template>
  <div class="list_item">
    <div class="txt_cont">
      <strong>{{ pcItem.title }}</strong>
      <p>
        {{ pcItem.content }}
      </p>
      <a href="javascript:;" class="mb_link_more" @click="$router.push(pcItem.path)"
        ><span class="hide">화살표</span>
        <svg xmlns="http://www.w3.org/2000/svg" width="40.5" height="32" viewBox="0 0 40.5 32">
          <g id="btn_mobilemore_serv" transform="translate(0.5)">
            <rect id="btn_base" width="40" height="32" rx="16" fill="none" />
            <path
              id="패스_2025"
              data-name="패스 2025"
              d="M353.667,3680l6,6-6,6"
              transform="translate(-335.167 -3669.5)"
              fill="none"
              stroke="#888"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            />
            <path
              id="패스_2026"
              data-name="패스 2026"
              d="M354.667,3688h-24"
              transform="translate(-330.167 -3671.5)"
              fill="none"
              stroke="#888"
              stroke-linecap="round"
              stroke-width="2"
            />
          </g></svg
      ></a>
    </div>
    <div class="img_cont" :class="pcItem.imgClass">
      <span class="hidden">{{ pcItem.title }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "SeviceInfoPcItem",
  props: {
    pcItem: {
      type: Object,
      default: () => {}
    }
  }
};
</script>
