<template>
  <h2 class="h2_title">
    {{title}}
    <span class="h2_desc">{{subTitle}}</span>
  </h2>
</template>

<script>
export default {
  name: 'PageTitle',
  props: {
    title: {
      type: String,
      default: '',
    },
    subTitle: {
      type: String,
      default: '',
    },
  },
};
</script>
