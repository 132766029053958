<template>
  <div id="content">
    <section class="cont_head">
      <AdminPageTitle title="문의상담 조회" subTitle="조회" />
    </section>

    <section class="cont_body">
      <fieldset>
        <legend>조건 및 추출조건 선택</legend>
        <InqurySearch @search-data="searchData" />
        <InquryList :list="inquryList" :pageInfo="pageInfo" />
        <Pagination :pageInfo="pageInfo" @change-page="changePage" />
      </fieldset>
    </section>
  </div>
</template>

<script>
import AdminPageTitle from "@/components/admin/AdminPageTitle.vue";
import Pagination from "@/components/admin/Pagination.vue";
import consultApi from "@/apis/consult";
import InqurySearch from "./compo/InqurySearch.vue";
import InquryList from "./compo/InquryList.vue";

export default {
  // name은 파일 이름을 쓰시면 됩니다. ex) Portfolio.vue => Portfolio
  name: "InquryConsultation",
  // components 위에서 임포트한 컴포넌트를 여기에 등록하고 위에 HTML영역에 태그로 사용가능합니다.
  components: {
    AdminPageTitle,
    InqurySearch,
    InquryList,
    Pagination
  },
  data() {
    return {
      inquryList: [],
      pageInfo: {
        totalNum: 0,
        pageNum: 1
      },
      searchForm: {
        name: "",
        startDate: "",
        endDate: "",
        status: "",
        consultType: ""
      }
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      const result = await consultApi.getConsult({
        pageNum: this.pageInfo.pageNum,
        ...this.searchForm
      });
      if (result.code === 500) {
        this.$alert("데이터 송신 중 오류가 발생했습니다.", "", "error");
        return;
      }
      this.pageInfo.totalNum = result.totalNum;
      this.inquryList = result.result;
    },
    changePage(pageNum) {
      this.pageInfo.pageNum = pageNum;
      this.init();
    },
    searchData(data) {
      this.pageInfo.pageNum = 1;
      this.searchForm = data;
      this.init();
    }
  }
};
</script>
