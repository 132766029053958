<template>
  <div id="content" class="recruit">
    <!-- cont_body// -->
    <section class="cont_body">
      <!-- inner// -->
      <div class="inner">
        <PageTitle title="모집분야" subTitle="엔더스 크루와 함께 할 전문 인재를 모집합니다." />
      </div>
      <!-- //inner -->
      <!-- 모집공고 영역// -->
      <div class="recruit_wrap">
        <!-- inner// -->
        <div class="inner">
          <!-- recruit list// -->
          <ul class="tab_list rct">
            <li :class="activeClass.all">
              <a href="#" @click.prevent="selectType('all')">전체</a>
            </li>
            <li :class="activeClass.front">
              <a href="#" @click.prevent="selectType('front')">프론트엔드</a>
            </li>
            <li :class="activeClass.back">
              <a href="#" @click.prevent="selectType('back')">백엔드</a>
            </li>
            <li :class="activeClass.design">
              <a href="#" @click.prevent="selectType('design')">디자인</a>
            </li>
            <li :class="activeClass.pm"><a href="#" @click.prevent="selectType('pm')">PM</a></li>
            <li :class="activeClass.plan">
              <a href="#" @click.prevent="selectType('plan')">기획</a>
            </li>
            <li :class="activeClass.sales">
              <a href="#" @click.prevent="selectType('sales')">영업/사업</a>
            </li>
          </ul>
          <div class="select_rct">
            <select @change="e => selectType(e.target.value)" v-model="selectBoxVal">
              <option value="all">전체</option>
              <option value="front">프론트엔드</option>
              <option value="back">백엔드</option>
              <option value="design">디자인</option>
              <option value="pm">PM</option>
              <option value="plan">기획</option>
              <option value="sales">영업/사업</option>
            </select>
          </div>
          <!-- //recruit list -->

          <!-- list_wrap// -->
          <div class="list_wrap recruit">
            <ul class="tab_content">
              <li v-for="(item, idx) in recruitData" :key="idx">
                <dl class="rct_cont">
                  <dt>
                    <strong>{{ item.title }}</strong>
                  </dt>
                  <dd>
                    <span v-for="sub in item.subInfo" :key="sub">{{ sub }}</span>
                  </dd>
                  <!-- tag_wrap// -->
                  <dd class="tag_wrap">
                    <span v-for="tag in item.tag" :key="tag">{{ tag }}</span>
                  </dd>
                  <!-- //tag_wrap -->
                </dl>
                <button class="btn_apply" @click="moveToApplyPage(item.type)">지원하기</button>
              </li>
              <li class="recruit_non" v-if="recruitData.length === 0">
                <p>모집중인 공고가 없습니다.</p>
              </li>
            </ul>
          </div>
          <!-- //list_wrap -->
        </div>
        <!-- //inner -->
      </div>
      <!-- //모집공고 영역 -->
    </section>
    <!-- //cont_body -->
  </div>
</template>

<script>
import PageTitle from '@/components/PageTitle.vue';

export default {
  name: 'RecruitList',
  components: {
    PageTitle,
  },
  data() {
    return {
      selectBoxVal: 'all',
      recruitData: [],
      allData: [
        {
          type: 'front',
          title: '프론트엔드 개발자 모집',
          subInfo: ['상시채용', '정규직or프리랜서', '경력 3년이상'],
          tag: [
            '#웹표준',
            '#HTML5',
            '#CSS3',
            '#React우대',
            '#Vue.js우대',
            '#반응형',
            '#JSP',
            '#JAVA',
            '#GitHub',
            '#JIRA우대',
            '#초대졸이상',
          ],
        },
        {
          type: 'back',
          title: 'JAVA 개발자 모집',
          subInfo: ['상시채용', '정규직or프리랜서', '경력 3년이상'],
          tag: [
            '#SI사업',
            '#솔루션사업',
            '#Cloud환경',
            '#AWS',
            '#애플리케이션',
            '#Web App',
            '#Web',
            '#정보처리기사우대',
            '#초대졸이상',
          ],
        },
        {
          type: 'back',
          title: '개발자 모집',
          subInfo: ['상시채용', '정규직or프리랜서', '경력 3년이상'],
          tag: [
            '#SI사업',
            '#솔루션사업',
            '#Cloud환경',
            '#AWS',
            '#애플리케이션',
            '#Web App',
            '#Web',
            '#C++',
            "#Python's",
            '#정보처리기사우대',
            '#초대졸이상',
          ],
        },
        {
          type: 'design',
          title: '디자이너 모집',
          subInfo: ['상시채용', '정규직or프리랜서', '경력 3년이상'],
          tag: [
            '#애플리케이션',
            '#Web App',
            '#Web',
            '#GUI제작',
            '#GitHub',
            '#JIRA우대',
            '#초대졸이상',
          ],
        },
        {
          type: 'pm',
          title: 'SI사업 PM 모집',
          subInfo: ['상시채용', '정규직or프리랜서', '경력 10년이상'],
          tag: ['#SI사업', '#IT경험자우대', '#이커머스경험자', '#금융권경험자', '#초대졸이상'],
        },
        {
          type: 'plan',
          title: '웹 기획자 모집',
          subInfo: ['상시채용', '정규직or프리랜서', '경력 3년이상'],
          tag: ['#화면설계', '#프론트엔드기획', '#백엔드기획', '#UIUX기획', '#초대졸이상'],
        },
      ],
      activeClass: {
        all: '',
        front: '',
        back: '',
        design: '',
        pm: '',
        plan: '',
        sales: '',
      },
    };
  },
  mounted() {
    this.selectType('all');
  },
  methods: {
    selectType(type) {
      this.selectBoxVal = type;
      this.activeTabBtn(type);
      if (type === 'all') {
        this.recruitData = this.allData;
      } else {
        this.recruitData = this.allData.filter((n) => n.type === type);
      }
    },
    moveToApplyPage(type) {
      console.log(type);
      this.$router.push({ name: 'RecruitApply', params: { type } });
    },
    activeTabBtn(type) {
      this.activeClass.all = '';
      this.activeClass.front = '';
      this.activeClass.back = '';
      this.activeClass.design = '';
      this.activeClass.pm = '';
      this.activeClass.plan = '';
      this.activeClass.sales = '';
      this.activeClass[type] = 'active';
    },
  },
};
</script>
