<template>
  <div class="graybox">
    <div class="list-area">
      <ul>
        <li class="col-half">
          <label>프로젝트 일자</label>
          <div class="list-item">
            <div class="datescope">
              <input type="date" id="start" name="datepicker" v-model="searchForm.startDate">
            </div>
            <span class="hyppen date"></span>
            <div class="datescope">
              <input type="date" id="end" name="datepicker" v-model="searchForm.endDate">
            </div>
          </div>
        </li>
        <li>
          <label>프로젝트명</label>
          <div class="list-item">
            <input type="text" placeholder="프로젝트명을 입력해주세요." v-model="searchForm.projectName">
          </div>
        </li>
        <li class="col-full">
          <label>노출여부</label>
          <div class="list-item">
            <div class="select">
              <select title="옵션 선택" v-model="searchForm.isUse">
                <option value="">선택</option>
                <option value="Y">Y</option>
                <option value="N">N</option>
              </select>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <SearchBtn @search-data="searchData" />
  </div>
</template>

<script>
import SearchBtn from '@/components/admin/SearchBtn.vue';
import moment from 'moment'

export default {
  name: 'PortfolioSearch',
  components: {
    SearchBtn,
  },
  data() {
    return {
      searchForm: {
        projectName: '',
        isUse: '',
        startDate: moment().format('YYYY-01-01'),
        endDate: moment().format('YYYY-MM-DD'),
      }
    }
  },
  methods: {
    searchData() {
      this.$emit('search-data', this.searchForm)
    }
  },
};
</script>

<style></style>
