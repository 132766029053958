<template>
    <router-view />
</template>
<script>

import '@/assets/css/reset.css';
import '@/assets/css/common.css';
import '@/assets/css/style.css';
import '@/assets/css/style_admin.css';

export default {
  name: 'App',
};
</script>
