<template>
  <div class="service_area srvc_about">
    <div class="inner">
      <h3 class="h3_ttl">Service information</h3>
      <!-- service_list// -->
      <div class="service_list type_pc">
        <SeviceInfoItem v-for="(item, idx) in serviceItem" :key="idx" :pcItem="item"/>
      </div>
      <!-- //type_pc -->

      <!-- service_list// -->
      <div class="service_list type_mb">
        <Swiper :options="swiperOptions">
          <SwiperSlide class="swiper-slide" v-for="(item, idx) in serviceItem" :key="idx" >
            <SeviceInfoItem :pcItem="item"/>
          </SwiperSlide>
          <div class="swiper-pagination mb_srv_pager swiper-pagination-bullets" slot="pagination"></div>
        </Swiper>
      </div>
      <!-- //type_mb-->
      <!-- //service_list -->
    </div>
  </div>
</template>

<script>
import SeviceInfoItem from './SeviceInfoItem.vue';

export default {
  name: 'ServiceInfo',
  components: {
    SeviceInfoItem,
  },
  data() {
    return {
      swiperOptions: {
        spaceBetween: 30,
        pagination: {
          el: '.swiper-pagination',
        },
      },
      serviceItem: [
        {
          title: 'E-BIZ 서비스',
          content:
            '온라인 비즈니스 사업의 성공을 여는\nE-BIZ 통합 서비스 입니다.\n심층 분석 및 컨설팅부터 운영까지 토탈 케어 서비스를 제공합니다.',
          path: '/service/ebiz',
          imgClass: 'bg_ebiz',
        },
        {
          title: '클라우드 트랜스포메이션',
          content:
            '클라우드 기반의 비즈니스 재창조를 위한 합리적이고 경제적인 클라우드 도입 서비스를 제공합니다.',
          path: '/service/cloud',
          imgClass: 'bg_cloud',
        },
        {
          title: '디지털 CX · UIUX',
          content:
            '고객 경험의 지속 성장과 최적의 채널\n전략 실현을 위한 혁신적인 UIUX 구현\n을 제공합니다.',
          path: '/service/dcx',
          imgClass: 'bg_uxui',
        },
        {
          title: '비즈니스 솔루션',
          content:
            '다양한 기업 마케팅 활동을 지원하는 저비용 고효율 솔루션을 만나보세요.',
          path: '/service/solution',
          imgClass: 'bg_solution',
        },
      ],
    };
  },
};
</script>
