<template>
  <div class="pf_area">
    <div class="inner">
      <h3 class="h3_ttl">Portfolio</h3>
      <a href="javascript:;" class="link_more" @click.prevent="$router.push('/portfolio')"
        >MORE<span
          >더보기화살표
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 6 10.5"
          >
            <defs>
              <clipPath id="clip-path">
                <rect id="box" width="6" height="10.5" fill="#ff6d6d" opacity="0.47" />
              </clipPath>
            </defs>
            <g id="ic__" data-name="ic_&gt;" clip-path="url(#clip-path)">
              <path
                id="arrow-ios-forward-fill"
                d="M14.135,16.387a.637.637,0,0,1-.49-1.044L16.5,11.931l-2.75-3.419a.651.651,0,1,1,1.025-.8l3.075,3.82a.637.637,0,0,1,0,.808l-3.183,3.82a.637.637,0,0,1-.528.229Z"
                transform="translate(-12.748 -6.706)"
                fill="#777"
              />
            </g>
          </svg> </span
      ></a>
    </div>
    <div class="layout_box">
      <div class="swiper-container pf_slider">
        <Swiper ref="mySwiper" :options="swiperOptions" class="swiper-wrapper pf_item">
          <SwiperSlide class="swiper-slide" v-for="(item, idx) in datas" :key="idx">
            <div class="thumb_pf">
              <img :src="`/files/portfolio/${item.main_img}`" />
            </div>
            <dl class="partner_desc">
              <dt>
                <strong>{{ item.project_name }}</strong>
              </dt>
              <dd>
                <span v-if="item.tag1">{{ item.tag1 }}</span>
                <span v-if="item.tag2">{{ item.tag2 }}</span>
                <span v-if="item.tag3">{{ item.tag3 }}</span>
              </dd>
            </dl>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  </div>
</template>

<script>
import portfolioApi from "@/apis/portfolio";

export default {
  name: "PortfolioInfo",
  components: {},
  mounted() {
    this.init();
  },
  data() {
    return {
      swiperOptions: {
        slidesPerView: "auto",
        spaceBetween: 30
      },
      datas: []
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    }
  },
  methods: {
    async init() {
      const result = await portfolioApi.getPortfolio({ pageNum: 1, isUse: "Y" });
      this.datas.push(...result.result);
    }
  }
};
</script>
