<template>
  <div class="paging" v-if="pageInfo.totalNum > 0">
    <button type="button" class="prev" :disabled="pageInfo.pageNum == 1" @click="chagePrevPage">
      이전</button
    ><!-- 비활성시 disabled 속성 추가 -->
    <button
      type="button"
      :class="pageInfo.pageNum == item ? 'active' : ''"
      v-for="item in Math.ceil(pageInfo.totalNum / 12)"
      :key="item"
      :value="item"
      @click="changePage"
    >
      {{ item }}
    </button>
    <button
      type="button"
      class="next"
      :disabled="Math.ceil(pageInfo.totalNum / 12) == pageInfo.pageNum"
      @click="chageNextPage"
    >
      다음</button
    ><!-- 비활성시 disabled 속성 추가 -->
  </div>
</template>

<script>
export default {
  name: "Pagination",
  props: {
    pageInfo: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    changePage(evnet) {
      if (evnet.target.value !== this.pageInfo.pageNum) {
        this.$emit("change-page", evnet.target.value);
      }
    },
    chagePrevPage() {
      this.$emit("change-page", parseInt(this.pageInfo.pageNum, 10) - 1);
    },
    chageNextPage() {
      this.$emit("change-page", parseInt(this.pageInfo.pageNum, 10) + 1);
    }
  }
};
</script>

<style></style>
