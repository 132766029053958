<template>
  <!-- faq_acco_wrap// -->
  <div class="faq_wrap" @click="$emit('open-faq', faqItem.id)">
    <div class="acco_title" :class="isActiveClass">
      <div class="ttl_wrap" :class="isActiveClass">
        <span class="txt_que">Q</span>
        <span>{{faqItem.title}}</span>
      </div>
    </div>
    <div class="acco_content" :class="isActiveClass">
      <p>{{faqItem.answer}}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FaqInfoItem',
  props: {
    faqItem: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    isActiveClass() {
      return this.faqItem.active ? 'active' : '';
    },
  },
};
</script>

<style></style>
