import { http } from '@/util/http';
// url 바뀔 예정
async function postPortfolio(data) {
  const form = new FormData();
  form.append('project_date', data.project_date);
  form.append('project_name', data.project_name);
  form.append('main_img', data.main_img);
  form.append('main_img_title', data.main_img_title);
  form.append('main_img_size', data.main_img_size);
  if (data.sub_imgs.length > 0) {
    data.sub_imgs.forEach((n, idx) => {
      form.append(`sub_imgs${idx + 1}`, n.file);
    });
  }
  form.append('tag1', data.tag1);
  form.append('tag2', data.tag2);
  form.append('tag3', data.tag3);
  form.append('purpose', data.purpose);
  form.append('intention', data.intention);
  form.append('is_use', data.is_use);
  form.append('feature', data.feature);
  try {
    const result = await http.post('/enders/api/portfolio', form);
    return result;
  } catch {
    return {
      code: 500,
    };
  }
}

async function updatePortfolioDetail(id, data) {
  const form = new FormData();
  form.append('project_date', data.project_date);
  form.append('project_name', data.project_name);
  form.append('main_img', data.main_img);
  form.append('main_img_title', data.main_img_title);
  form.append('main_img_size', data.main_img_size);
  if (data.sub_imgs.length > 0) {
    data.sub_imgs.forEach((n, idx) => {
      form.append(`sub_imgs${idx + 1}`, n.file);
    });
  }
  for (let index = 0; index < data.sub_imgs_delete_info.deleted.length; index += 1) {
    form.append(data.sub_imgs_delete_info.deleted[index], 'DELETE');
  }
  form.append('tag1', data.tag1);
  form.append('tag2', data.tag2);
  form.append('tag3', data.tag3);
  form.append('purpose', data.purpose);
  form.append('intention', data.intention);
  form.append('is_use', data.is_use);
  form.append('feature', data.feature);
  try {
    const result = await http.put(`/enders/api/portfolio/${id}`, form);
    return result;
  } catch {
    return {
      code: 500,
    };
  }
}

async function getPortfolio(data) {
  try {
    const result = await http.get('/enders/api/portfolio', data);
    return result;
  } catch {
    return {
      code: 500,
    };
  }
}

async function getPortfolioDetail(id) {
  try {
    const result = await http.get(`/enders/api/portfolio/${id}`);
    return result;
  } catch {
    return {
      code: 500,
    };
  }
}

async function deletePortfolioDetail(id) {
  try {
    const result = await http.delete(`/enders/api/portfolio/${id}`);
    return result;
  } catch {
    return {
      code: 500,
    };
  }
}

export default {
  getPortfolio,
  getPortfolioDetail,
  deletePortfolioDetail,
  updatePortfolioDetail,
  postPortfolio
};
