<template>
  <div class="visual_crew">
    <div class="inner">
      <div class="crew_txt">
        <h2 class="itr_ttl">
          <span class="col_em">Welcome!</span>
          <strong>
            <em>독특한 생각으로</em>
            <span class="mb_block">세상을 바꿀</span>
            엔더스 크루를 기다립니다
          </strong>
        </h2>
        <a href="javascript:;" class="link_more" @click.prevent="$router.push('/crew/enderscrew')"
          >MORE<span
            >더보기화살표
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 6 10.5"
            >
              <defs>
                <clipPath id="clip-path">
                  <rect id="box" width="6" height="10.5" fill="#ff6d6d" opacity="0.47" />
                </clipPath>
              </defs>
              <g id="ic__" data-name="ic_&gt;" clip-path="url(#clip-path)">
                <path
                  id="arrow-ios-forward-fill"
                  d="M14.135,16.387a.637.637,0,0,1-.49-1.044L16.5,11.931l-2.75-3.419a.651.651,0,1,1,1.025-.8l3.075,3.82a.637.637,0,0,1,0,.808l-3.183,3.82a.637.637,0,0,1-.528.229Z"
                  transform="translate(-12.748 -6.706)"
                  fill="#777"
                />
              </g>
            </svg> </span
        ></a>
      </div>
      <div class="crew_desc">
        <img src="@/assets/img/main/visual_crew.png" alt="프로젝트 소개 이미지" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WelcomeCrew"
};
</script>

<style></style>
