<template>
  <div class="graybox editapplicant">
    <div class="list-area">
      <ul>
        <li>
          <label class="required"> 문의자명</label>
          <div class="list-item">
            <p class="inline-txt">{{ detail.name }}</p>
          </div>
        </li>
        <li>
          <label class="required">진행사항</label>
          <div class="list-item">
            <div class="select">
              <select title="옵션 선택" v-model="updateInfo.status">
                <option value="서류심사 요청">서류심사 요청</option>
                <option value="서류심사 검토">서류심사 검토</option>
                <option value="실무면접 완료">실무면접 완료</option>
                <option value="임원면접 완료">임원면접 완료</option>
                <option value="스팸문의">스팸문의</option>
              </select>
            </div>
          </div>
        </li>
        <li class="col-full">
          <label class="required">지원분야</label>
          <div class="list-item">
            <p class="inline-txt">{{ detail.apply_type }}</p>
          </div>
        </li>
        <li class="col-full">
          <label class="required">연락처</label>
          <div class="list-item">
            <p class="inline-txt">{{ detail.phone }}</p>
          </div>
        </li>
        <li class="col-full">
          <label class="required">이메일</label>
          <div class="list-item">
            <p class="inline-txt">{{ detail.email }}</p>
          </div>
        </li>
        <li class="col-full">
          <label>비고</label>
          <div class="list-item">
            <input type="text" placeholder="" v-model="updateInfo.memo" />
          </div>
        </li>
        <li class="col-full">
          <label>이력서</label>
          <div class="list-item">
            <div class="state_added">
              <div class="data_box">
                <div class="data_info">
                  <p class="inline-txt">
                    <a
                      href="javascript:;"
                      class="col_blue"
                      @click="downloadFile(detail.resume_title)"
                      >{{ detail.resume_title }}</a
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li class="col-full">
          <label>포트폴리오</label>
          <div class="list-item">
            <div class="state_added" v-if="detail.portfolio_title1">
              <div class="data_box">
                <div class="data_info">
                  <p class="inline-txt">
                    <a
                      href="javascript:;"
                      class="col_blue"
                      @click="downloadFile(detail.portfolio_title1)"
                      >{{ detail.portfolio_title1 }}</a
                    >
                  </p>
                </div>
              </div>
            </div>
            <div class="state_added" v-if="detail.portfolio_title2">
              <div class="data_box">
                <div class="data_info">
                  <p class="inline-txt">
                    <a
                      href="javascript:;"
                      class="col_blue"
                      @click="download(detail.portfolio_title2)"
                      >{{ detail.portfolio_title2 }}</a
                    >
                  </p>
                </div>
              </div>
            </div>
            <div class="state_added" v-if="detail.portfolio_title3">
              <div class="data_box">
                <div class="data_info">
                  <p class="inline-txt">
                    <a
                      href="javascript:;"
                      class="col_blue"
                      @click="download(detail.portfolio_title3)"
                      >{{ detail.portfolio_title3 }}</a
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li class="col-full">
          <label>지원 일시</label>
          <div class="list-item">
            <p class="inline-txt">{{ changeDateTime(detail.reg_date) }}</p>
          </div>
        </li>
        <li class="col-full">
          <label>수정 일시</label>
          <div class="list-item">
            <p class="inline-txt">{{ changeDateTime(detail.update_date) }}</p>
          </div>
        </li>
      </ul>
    </div>
    <ListBtn />
    <div class="btn-wrap">
      <button type="button" class="btn big" @click="deleteApplicant()">삭제</button>
      <button type="button" class="btn big fullblue" @click="updateApplicant(updateInfo)">
        저장
      </button>
    </div>
  </div>
</template>

<script>
import ListBtn from "@/components/admin/ListBtn.vue";
import applicantApi from "@/apis/applicant";
import axios from "axios";
import { datetimeToString } from "@/util/datetime";

export default {
  name: "EditContent",
  components: {
    ListBtn
  },
  mounted() {
    this.init();
  },
  data() {
    return {
      detail: {},
      updateInfo: {
        status: "",
        memo: ""
      }
    };
  },
  watch: {
    detail() {
      this.updateInfo.status = this.detail.status;
      this.updateInfo.memo = this.detail.memo;
    }
  },
  methods: {
    async init() {
      const result = await applicantApi.getApplicantDetail(this.$route.params.id);
      if (result.code === 500) {
        await this.$alert("데이터 송신 중 오류가 발생했습니다.", "", "error");
        this.$router.push("/admin/applicantManagement");
        return;
      }
      this.detail = result.result;
    },
    async deleteApplicant() {
      this.$confirm("삭제된 정보는 복구가 불가합니다. 정말 삭제하겠습니까?", "", "warning")
        .then(async () => {
          const result = await applicantApi.deleteApplicantDetail(this.$route.params.id);
          if (result.code === 500) {
            await this.$alert("데이터 삭제 중 오류가 발생했습니다.", "", "error");
            return;
          }
          await this.$alert("삭제 되었습니다.", "", "success");
          this.$router.push("/admin/applicantManagement");
        })
        .catch(() => {});
    },
    async updateApplicant(data) {
      this.$confirm("입력 정보를 업데이트 하시겠습니까?", "", "question")
        .then(async () => {
          const result = await applicantApi.updateApplicantDetail(this.$route.params.id, data);
          if (result.code === 500) {
            await this.$alert("데이터 업데이트 중 오류가 발생했습니다.", "", "error");
            return;
          }
          await this.$alert("업데이트 되었습니다.", "", "success");
          this.$router.push("/admin/applicantManagement");
        })
        .catch(() => {});
    },
    async downloadFile(title) {
      const { data } = await axios.get(`https://www.enders.co.kr/files/${title}`, {
        responseType: "blob"
      });
      const url = window.URL.createObjectURL(new Blob([data]));
      console.log(url);
      const downloadLink = document.createElement("a");
      downloadLink.setAttribute("download", `${title.substr(9)}`);
      downloadLink.href = url;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      downloadLink.remove();
    },
    changeDateTime(date) {
      if (date) {
        const parsingData = new Date(date);
        return datetimeToString(parsingData);
      }
      return "";
    }
  }
};
</script>
