<template>
  <div id="content" class="portfolio">
    <section class="cont_body">
      <div class="inner">
        <!-- 대제목 영역
          대제목은 공통으로 사용하여 따로 공통 컴퍼넌트로 빼놨습니다.
          conponents -> PageTitle 가져다 쓰시면 됩니다.
        -->
        <PageTitle
          title="Portfolio"
          subTitle="엔더스의 파트너가 되어 변화된 기업 사례를 확인해보세요!"
        />

        <!-- area_partners
          Content를 따로 컴포넌트로 분리한 이유는 유지보수를 위해 메인 vue의 코드를 간결하게 하기 위해서입니다.
        -->
        <Content :datas="datas" @more-datas="more" :isMoreBtn="isMoreBtn" />

        <!-- 문의상담영역
          문의상담영역 또한 대제목과 마찬가지로 공통으로 몇군데 쓰이기 때문에 공통 컴포넌트로 빼놨습니다.
          conponents -> ConsultBanner 가져다 쓰시면 됩니다.
        // -->
        <ConsultBanner />
      </div>
    </section>
  </div>
</template>
<script>
import PageTitle from '@/components/PageTitle.vue';
import ConsultBanner from '@/components/ConsultBanner.vue';
import portfolioApi from '@/apis/portfolio'
import Content from './compo/Content.vue';

export default {
  // name은 파일 이름을 쓰시면 됩니다. ex) Portfolio.vue => Portfolio
  name: 'Portfolio',
  // components 위에서 임포트한 컴포넌트를 여기에 등록하고 위에 HTML영역에 태그로 사용가능합니다.
  components: {
    PageTitle,
    ConsultBanner,
    Content,
  },
  mounted() {
    this.init();
  },
  data() {
    return {
      // 사용 변수를 정의, 선언해 놓는 곳 입니다.
      datas: [],
      isMoreBtn: true,
      pageNum: 1,
    };
  },
  methods: {
    // 사용 함수를 정의해 놓는 곳 입니다.
    async init(){
      const result = await portfolioApi.getPortfolio({pageNum: this.pageNum, front: 'O', isUse: 'Y'});
      this.datas.push(...result.result);
      this.isMoreBtn = this.datas.length < result.totalNum;
    },
    more() {
      this.pageNum += 1;
      this.init();
    },
  },
};
</script>
