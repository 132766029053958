<template>
  <div class="list_item">
    <div class="lf_img"><img :src="pcItem.img"></div>
    <div class="rt_txt">
      <strong>{{pcItem.title}}</strong>
      <p>
        {{pcItem.content}}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SeviceInfoPcItem',
  props: {
    pcItem: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
