<template>
  <div class="visual_intro">
    <div class="inner">
      <div class="intro_txt">
        <h2 class="itr_ttl">
          <span class="col_em">We Are!</span><br />
          <strong>
            프로젝트를 대하는 우리들의 자세
          </strong>
        </h2>
        <p>
          절대적 강자들의 창의, 혁신, 서비스 마인드가 클라우드와 만나 지금의 엔더스를 만들었습니다.
          더 나은 E-비즈니스를 위해 끊임없이 연구하며 “원하는 모든 것의 창조” 원칙을 지켜
          나가겠습니다.
        </p>
        <a href="javascript:;" class="link_more" @click="$router.push('/about')"
          >MORE<span
            >더보기화살표
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 6 10.5"
            >
              <defs>
                <clipPath id="clip-path">
                  <rect id="box" width="6" height="10.5" fill="#ff6d6d" opacity="0.47" />
                </clipPath>
              </defs>
              <g id="ic__" data-name="ic_&gt;" clip-path="url(#clip-path)">
                <path
                  id="arrow-ios-forward-fill"
                  d="M14.135,16.387a.637.637,0,0,1-.49-1.044L16.5,11.931l-2.75-3.419a.651.651,0,1,1,1.025-.8l3.075,3.82a.637.637,0,0,1,0,.808l-3.183,3.82a.637.637,0,0,1-.528.229Z"
                  transform="translate(-12.748 -6.706)"
                  fill="#777"
                />
              </g>
            </svg> </span
        ></a>
      </div>

      <div class="intro_desc">
        <img src="@/assets/img/main/visual_intro.png" alt="프로젝트 소개 이미지" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VisualIntro",
  data() {
    return {};
  }
};
</script>
