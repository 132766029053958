<template>
  <div class="service_area srvc_solution">
    <div class="inner">
      <h3 class="h3_ttl">Ezen Cloud 주요기능</h3>
      <!-- service_list// -->
      <div class="service_list type_pc">
        <SeviceInfoItem v-for="(item, idx) in serviceItem" :key="idx" :pcItem="item"/>
      </div>
      <!-- //type_pc -->

      <!-- service_list// -->
      <div class="service_list type_mb">
        <Swiper :options="swiperOptions">
          <SwiperSlide class="swiper-slide" v-for="(item, idx) in serviceItem" :key="idx" >
            <SeviceInfoItem :pcItem="item"/>
          </SwiperSlide>
          <div class="swiper-pagination mb_srv_pager swiper-pagination-bullets" slot="pagination"></div>
        </Swiper>
      </div>
      <!-- //type_mb-->
      <!-- //service_list -->
    </div>
  </div>
</template>

<script>
import SeviceInfoItem from './SeviceInfoItem.vue';

export default {
  name: 'ServiceInfo',
  components: {
    SeviceInfoItem,
  },
  data() {
    return {
      swiperOptions: {
        spaceBetween: 30,
        pagination: {
          el: '.swiper-pagination',
        },
      },
      serviceItem: [
        {
          img: require('@/assets/img/sub/axon/erp_01.png'),
          subTitle: '더 똑똑한',
          title: '매출관리 솔루션',
          content:
            '전사업무, 회원, 수당, 물류, 일정, 주문 관리 및 승급처리, UMS 서비스 등을 중복 업무 없이 더욱 빨라진 속도로 경험해 보세요.',
          f1Title: '새로운 차세대 서비스',
          f1Content: 'IT전문가들이 만들 새로운 서비스',
          f2Title: '구축 - 운영 - 최적화',
          f2Content: '업무의 시행착오를 줄이고, 체계적이고 안정적인 업무 지원',
          f3Title: '업무 효율 증대',
          f3Content: '단축키, 즐겨찾기 기능을 통한 메뉴 커스터마이징',
        },
        {
          img: require('@/assets/img/sub/axon/erp_02.png'),
          subTitle: '나만의 프리미엄',
          title: '마이오피스',
          content:
            '모바일, 태블릿, PC에 최적화된 프리미엄 마이오피스로 주문 조회는 물론 업종별 실적관리, 회원관리, ADS 정기구독 등을 스마트하게 도와드립니다.',
          f1Title: '데이터 시각화',
          f1Content: '업무에 필요한 데이터를 시각화하여 그래프로 한눈에 확인',
          f2Title: '정확한 실적관리 및 편리한 계보도 시스템',
          f2Content: '실시간으로 확인 가능한 스마트한 수당관리, 직관적이고 스피드한 계보도 차트',
          f3Title: '빈틈없는 보안으로 안전한 데이터 관리',
          f3Content: '클라우드 방화벽, 데이터 암호화, 로그 관리 등 전반적인 보안 체계 구축',
        },
        {
          img: require('@/assets/img/sub/axon/erp_03.png'),
          subTitle: '사업에 필요한 A-Z까지',
          title: '맞춤형 홈페이지 제작 / 쇼핑몰 관리',
          content:
            '브랜드 가치와 이미지를 고려한 홈페이지 제작 서비스부터 쇼핑몰 마케팅, 간편 결제 시스템, 상품 등록, 배송 처리까지 모든 것을 한 번에 해결할 수 있습니다.',
          f1Title: '맞춤형 홈페이지 제작',
          f1Content: '엄선된 디자인으로 브랜드 이미지에 어울리는 맞춤형 홈페이지 제작',
          f2Title: '쇼핑몰 관리',
          f2Content: '쇼핑몰이라면 당연히 갖춰야 하는 상품 관리 기능부터 배송, 결제, 마케팅까지 깔끔하게',
          f3Title: '',
          f3Content: '',
        },
        {
          img: require('@/assets/img/sub/axon/erp_04.png'),
          subTitle: '설치가 필요 없는 국내 최초',
          title: '클라우드형 서비스',
          content:
            '클라우드로 전환하기 위해서는 기술적인 부분뿐만 아니라 비즈니스 측면도 함께 고려해야 합니다. 수많은 고객사의 클라우드 인프라 구축 경험과 노하우를 가지고 있습니다. 클라우드 전환과 안정된 서비스를 경험해 보세요.',
          f1Title: '클라우드 전환 엔더스와 함께',
          f1Content: '클라우드에 대한 다양한 경험과 지식을 바탕으로 인프라와 DB 운영관리, 보안 진단까지 뛰어난 역량을 지닌 클라우드 전문가 보유',
          f2Title: '',
          f2Content: '',
          f3Title: '',
          f3Content: '',
        },
      ],
    };
  },
};
</script>
