import { http } from '@/util/http';
// url 바뀔 예정
async function getApplicant(data) {
  try {
    const result = await http.get('/enders/api/applicant', data);
    return result;
  } catch {
    return {
      code: 500,
    };
  }
}

async function getApplicantDetail(id) {
  try {
    const result = await http.get(`/enders/api/applicant/${id}`);
    return result;
  } catch {
    return {
      code: 500,
    };
  }
}

async function deleteApplicantDetail(id) {
  try {
    const result = await http.delete(`/enders/api/applicant/${id}`);
    return result;
  } catch {
    return {
      code: 500,
    };
  }
}

async function createApplicantDetail(data) {
  try {
    const result = await http.post('/enders/api/applicant', data);
    return result;
  } catch {
    return {
      code: 500,
    };
  }
}

async function updateApplicantDetail(id, data) {
  try {
    const result = await http.put(`/enders/api/applicant/${id}`, data);
    return result;
  } catch {
    return {
      code: 500,
    };
  }
}

export default {
  getApplicant,
  getApplicantDetail,
  createApplicantDetail,
  deleteApplicantDetail,
  updateApplicantDetail,
};
