<template>
  <div class="graybox">
    <div class="list-area">
      <ul>
        <li class="col-full">
          <label>문의 일자</label>
          <div class="list-item">
            <div class="datescope">
              <input type="date" id="start" name="datepicker" v-model="searchForm.startDate" />
            </div>
            <span class="hyppen date"></span>
            <div class="datescope">
              <input type="date" id="end" name="datepicker" v-model="searchForm.endDate" />
            </div>
          </div>
        </li>
        <li style="border-top:1px solid #E1E1E1">
          <label>문의 서비스</label>
          <div class="list-item">
            <div class="select">
              <select title="옵션 선택" v-model="searchForm.consultType">
                <option value="">선택</option>
                <option value="AXonUMS">AXonUMS</option>
                <option value="AXonEIS">AXonEIS</option>
              </select>
            </div>
          </div>
        </li>
        <li>
          <label>진행 상황</label>
          <div class="list-item">
            <div class="select">
              <select title="옵션 선택" v-model="searchForm.status">
                <option value="">선택</option>
                <option value="상담요청">상담요청</option>
                <option value="연락완료">연락완료</option>
                <option value="상담종결">상담종결</option>
                <option value="스팸문의">스팸문의</option>
              </select>
            </div>
          </div>
        </li>
        <li class="col-full">
          <label>문의자명</label>
          <div class="list-item">
            <input type="text" placeholder="문의자명을 입력해주세요." v-model="searchForm.name" />
          </div>
        </li>
      </ul>
    </div>
    <SearchBtn @search-data="searchData" />
  </div>
</template>

<script>
import SearchBtn from "@/components/admin/SearchBtn.vue";
import moment from "moment";

export default {
  name: "InqurySearch",
  components: {
    SearchBtn
  },
  data() {
    return {
      searchForm: {
        startDate: moment().format("YYYY-01-01"),
        endDate: moment().format("YYYY-MM-DD"),
        status: "",
        consultType: ""
      }
    };
  },
  methods: {
    searchData() {
      this.$emit("search-data", {
        ...this.searchForm,
        ...{ endDate: `${this.searchForm.endDate} 23:59:59` }
      });
    }
  }
};
</script>

<style></style>
