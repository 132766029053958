<template>
  <div class="graybox">
    <p class="required">*필수기재 항목</p>
    <div class="list-area">
      <ul>
        <li>
          <label class="required">프로젝트 일자</label>
          <div class="list-item">
            <div class="datepicker">
              <input type="date" id="start" name="datepicker" v-model="portfolio.project_date">
            </div>
          </div>
        </li>
        <li>
          <label class="required">노출여부</label>
          <div class="list-item">
            <!-- 라디오버튼 -->
            <label for="rdo_01">
              <input type="radio" id="rdo_01" name="chk_condition" v-model="portfolio.is_use" value="Y"><span class="col_em col_gr">Y</span>
            </label>
            <label for="rdo_02">
              <input type="radio" id="rdo_02" name="chk_condition" v-model="portfolio.is_use" value="N"><span class="col_red" style="margin-right:0;">N</span>
            </label>
          </div>
        </li>
        <li class="col-full">
          <label class="required">프로젝트명</label>
          <div class="list-item">
            <input type="text" placeholder="프로젝트명을 입력해주세요." v-model="portfolio.project_name">
          </div>
        </li>
        <li class="col-full">
          <label class="required">분류1</label>
          <div class="list-item">
            <div class="select">
              <select title="옵션 선택" v-model="portfolio.tag1">
                <option value="">선택</option>
                <option value="Mobile app">Mobile app</option>
                <option value="App">App</option>
                <option value="Web">Web</option>
                <option value="Web App">Web App</option>
              </select>
            </div>
          </div>
        </li>
        <li class="col-full">
          <label>분류2</label>
          <div class="list-item">
            <div class="select">
              <select title="옵션 선택" v-model="portfolio.tag2">
                <option value="">선택</option>
                <option value="Cloud">Cloud</option>
                <option value="UMS">UMS</option>
                <option value="EIS">EIS</option>
                <option value="Serverless">Serverless</option>
                <option value="UIUX">UIUX</option>
                <option value="반응형">반응형</option>
                <option value="E-BIZ">E-BIZ</option>
                <option value="빅데이터">빅데이터</option>
              </select>
            </div>
          </div>
        </li>
        <li class="col-full">
          <label>분류3</label>
          <div class="list-item">
            <div class="select">
              <select title="옵션 선택" v-model="portfolio.tag3">
                <option value="">선택</option>
                <option value="Cloud">Cloud</option>
                <option value="UMS">UMS</option>
                <option value="EIS">EIS</option>
                <option value="Serverless">Serverless</option>
                <option value="UIUX">UIUX</option>
                <option value="반응형">반응형</option>
                <option value="E-BIZ">E-BIZ</option>
                <option value="빅데이터">빅데이터</option>
              </select>
            </div>
          </div>
        </li>

        <li class="col-full">
          <label>제작목적</label>
          <div class="list-item">
            <textarea
              placeholder="1000자 이내로 작성해주세요."
              maxlength="1000"
              v-model="portfolio.purpose"
            ></textarea>
          </div>
        </li>
        <li class="col-full">
          <label>기획의도</label>
          <div class="list-item">
            <textarea
              placeholder="1000자 이내로 작성해주세요."
              maxlength="1000"
              v-model="portfolio.intention"
            ></textarea>
          </div>
        </li>
        <li class="col-full">
          <label>특징</label>
          <div class="list-item">
            <textarea
              placeholder="1000자 이내로 작성해주세요."
              maxlength="1000"
              v-model="portfolio.feature"
            ></textarea>
          </div>
        </li>

        <li class="col-full">
          <label class="required">대표 이미지</label>
          <div class="list-item">
            <div class="state_added" v-if="portfolio.main_img_YN">
              <div class="data_box">
                <div class="data_info">
                  <strong>{{ portfolio.main_img_title }}</strong>
                  <span>{{ Math.floor(portfolio.main_img_size / 1024) }} MB</span>
                </div>
              </div>
              <button class="btn" @click.prevent="deleteMainImage(idx)">삭제</button>
            </div>
            <label for="mainThumb" class="attach" v-if="!portfolio.main_img_YN">
              파일첨부
            </label>
            <input type="file" id="mainThumb" hidden @change="inputMainImage($event)" />
          </div>
        </li>

        <li class="col-full">
          <label>부가 이미지</label>
          <div class="list-item">
            <div class="state_added" v-for="(sub_img, idx) in portfolio.sub_imgs" :key="idx">
              <div class="data_box">
                <div class="data_info">
                  <strong>{{ sub_img.title }}</strong>
                  <span>{{ Math.floor(sub_img.size / 1024) }} MB</span>
                </div>
              </div>
              <button class="btn" @click.prevent="deleteSubImages(idx)">삭제</button>
            </div>
            <label for="subThumb" class="attach" v-if="!portfolio.sub_imgs_max">
              파일첨부
            </label>
            <input type="file" id="subThumb" hidden @change="inputSubImages($event)"/>
          </div>
        </li>
      </ul>
    </div>
    <div class="btn-wrap">
      <button type="button" class="btn big" @click="$router.back()">취소</button>
      <button type="button" class="btn big fullblue" @click="postPortfolio(portfolio)">등록</button>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import portfolioApi from '@/apis/portfolio';

export default {
  name: 'EnrollContent',
  data() {
    return {
      portfolio:  {
        project_date: moment().format('YYYY-MM-DD'),
        project_name: '',
        tag1: '',
        tag2: '',
        tag3: '',
        purpose: '',
        intention: '',
        is_use: 'Y',
        feature: '',
        main_img: '',
        main_img_YN: false,
        main_img_title: '',
        main_img_size: '',
        sub_imgs: [],
        sub_imgs_max: false
      }
    }
  },
  created() {
    console.log(this.portfolio)
  },
  methods: {
		async postPortfolio(portfolio) {
      this.$confirm("입력 정보를 등록 하시겠습니까?", "", "question")
        .then(async () => {
          const result = await portfolioApi.postPortfolio(portfolio);
          if (result.code === 500) {
            await this.$alert("데이터 등록 중 오류가 발생했습니다.", "", "error");
            return;
          }
          await this.$alert("등록 되었습니다.", "", "success");
          this.$router.push("/admin/portfolioManagement");
        })
        .catch(() => {this.$alert("오류");});
    },
    inputMainImage(event) {
      console.dir(event.target);
      if (event.target.files.length > 0) {
        this.portfolio.main_img_title = event.target.files[0].name;
        this.portfolio.main_img_size = event.target.files[0].size;
        // eslint-disable-next-line prefer-destructuring
        this.portfolio.main_img = event.target.files[0];
        this.portfolio.main_img_YN = true;
      }
    },
    inputSubImages(event) {
      if (event.target.files.length > 0) {
        const inputFile = event.target.files[0];
        this.portfolio.sub_imgs.push({
          title: inputFile.name,
          size: inputFile.size,
          file: inputFile,
        });
        this.portfolio.sub_imgs_max = this.portfolio.sub_imgs.length === 3;
      }
    },
    deleteMainimage(idx) {
      this.portfolio.main_img.splice(idx, 1);
      this.portfolio.main_img_YN = false;
    },
    deleteSubImages(idx) {
      this.portfolio.sub_imgs.splice(idx, 1);
      this.portfolio.sub_imgs_max = false;
    },
	}
};
</script>

<style></style>
