<template>
    <!-- content// -->
    <div id="content" class="rct_process">
        <!-- cont_body// -->
        <section class="cont_body">
            <!-- inner// -->
            <div class="inner">
                <!-- 대제목 영역// -->
                <PageTitle
                title="지원정보"
                subTitle="엔더스 크루 지원을 위한 유용한 정보를 확인해보세요."
                />
                <!-- //대제목 영역 -->
            </div>
            <!-- //inner -->
            <!-- work_prc// -->
            <div class="work_prc">
                    <div class="inner">
                    <h3>
                        <strong>인재상</strong>
                        <span>멈추지 않는 도전과 독특한 아이디어를 발산하는 우리는 엔더스 크루입니다.</span>
                    </h3>
                    <div class="work_stepwrap">
                        <ol>
                            <li>
                                <div class="icon_circle"><img src="@/assets/img/sub/recruit/recruit_prc_01.png" alt="인재1"></div>
                                <div class="work_desc">
                                    <strong>
                                        진취적 자세를 가진 인재
                                    </strong>
                                    <p>
                                        <span>실패를 두려워하지 않고 </span>
                                        <span>성공을 향한 도전을 멈추지 않는 </span>
                                        자신감 있는 인재
                                    </p>
                                </div>
                            </li>
                            <li>
                                <div class="icon_circle"><img src="@/assets/img/sub/recruit/recruit_prc_02.png" alt="인재2"></div>
                                <div class="work_desc">
                                    <strong>
                                        적극적인 자율 인재
                                    </strong>
                                    <p>
                                        <span>유연한 사고로  </span>
                                        <span>가능성을 찾고 능동적으로 행동하는 </span>
                                        적극적인 자율 인재
                                    </p>
                                </div>
                            </li>
                            <li>
                                <div class="icon_circle"><img src="@/assets/img/sub/recruit/recruit_prc_03.png" alt="인재3"></div>
                                <div class="work_desc">
                                    <strong>
                                        가치를 창조하는 인재
                                    </strong>
                                    <p>
                                        <span>독특한 시각과 접근으로 </span>
                                        <span>변화와 혁신을 추구하며 끊임없이 </span>
                                        가치를 창조하는 인재
                                    </p>
                                </div>
                            </li>

                        </ol>
                    </div>
                </div>
            </div>
            <!-- //work_prc -->

            <!-- 모집공고 영역// -->
            <div class="process_wrap">
                <!-- inner// -->
                <div class="inner">
                    <!-- step_box// -->
                    <div class="step_box">
                        <h3 class="h3_title">채용 프로세스</h3>
                        <p class="h3_desc">
                            서류전형을 거쳐 실무 면접 > 임원 면접으로 인터뷰가 진행됩니다.<br>
                            엔더스 홈페이지의 크루 지원 또는 취업사이트 공고를 통해 지원 가능합니다.
                        </p>
                        <!-- prc_order// -->
                        <ol class="prc_order">
                            <li>
                                <div>
                                    <strong>
                                        <span class="col_blu">STEP01</span>
                                        서류전형
                                    </strong>
                                    <span><img src="@/assets/img/sub/recruit/icon_doc.png" alt="서류전형아이콘"></span>
                                </div>
                            </li>

                            <li>
                                <div>
                                    <strong>
                                        <span class="col_blu">STEP02</span>
                                        실무 면접
                                    </strong>
                                    <span>
                                        <img src="@/assets/img/sub/recruit/icon_interview.png" alt="실무면접아이콘">
                                    </span>
                                </div>
                            </li>

                            <li>
                                <div>
                                    <strong>
                                        <span class="col_blu">STEP03</span>
                                        임원 면접
                                    </strong>
                                    <span>
                                        <img src="@/assets/img/sub/recruit/icon_person.png" alt="임원면접아이콘">
                                    </span>
                                </div>
                            </li>

                            <li>
                                <div>
                                    <strong>
                                        <span class="col_blu">STEP04</span>
                                        합격 통보
                                    </strong>
                                    <span>
                                        <img src="@/assets/img/sub/recruit/icon_pass.png" alt="합격통보아이콘">
                                    </span>
                                </div>
                            </li>
                        </ol>
                        <!-- //prc_order -->
                        <a href="#" @click.prevent="movePage('/crew/recruit')" class="link_apply">모집분야 보기</a>
                        <!-- prc_desc// -->
                        <div class="prc_bg">
                            <img src="@/assets/img/sub/recruit/prcss_bg.png" alt="프로세스 배경">
                        </div>
                        <!-- //prc_desc -->
                    </div>
                    <!-- //step_box -->

                    <!-- faq_box// -->
                    <FaqInfo />
                    <!-- //faq_box -->

                </div>
                <!-- //inner -->
            </div>
            <!-- //모집공고 영역 -->

        </section>
        <!-- //cont_body -->

        <!-- top button//  -->
        <button class="btn_top"><span class="hidden">TOP</span></button>
        <!-- //top button -->
    </div>
    <!-- //content -->
</template>
<script>
import PageTitle from '@/components/PageTitle.vue';
import FaqInfo from './process-compo/FaqInfo.vue';

export default {
  // name은 파일 이름을 쓰시면 됩니다. ex) Portfolio.vue => Portfolio
  name: 'recruitprocess',
  // components 위에서 임포트한 컴포넌트를 여기에 등록하고 위에 HTML영역에 태그로 사용가능합니다.
  components: {
    PageTitle,
    FaqInfo,
  },
  methods: {
    movePage(path) {
      if (this.$route.path !== path) {
        this.$router.push(path);
      }
    },
  },
};
</script>
