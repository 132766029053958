<template>
  <div id="content">
		<section class="cont_head">
			<AdminPageTitle
			title="지원자 관리"
			subTitle="조회"
			/>
		</section>

		<section class="cont_body">
			<fieldset>
				<legend>조건 및 추출조건 선택</legend>
				<ApplicantSearch @search-data="searchData" />
				<ApplicantList :list="applicantList" :pageInfo="pageInfo" />
				<Pagination :pageInfo="pageInfo" @change-page="changePage" />
			</fieldset>
		</section>
	</div>
</template>

<script>
import AdminPageTitle from '@/components/admin/AdminPageTitle.vue';
import Pagination from '@/components/admin/Pagination.vue';
import applicantApi from '@/apis/applicant';
import ApplicantSearch from './compo/ApplicantSearch.vue';
import ApplicantList from './compo/ApplicantList.vue';

export default {
  // name은 파일 이름을 쓰시면 됩니다. ex) Portfolio.vue => Portfolio
  name: 'ApplicantManagement',
  // components 위에서 임포트한 컴포넌트를 여기에 등록하고 위에 HTML영역에 태그로 사용가능합니다.
  components: {
    AdminPageTitle,
		ApplicantSearch,
		ApplicantList,
		Pagination,
  },
	data() {
		return {
			applicantList: [],
			searchForm: {
				name: '',
        startDate: '',
        endDate: '',
        status: ''
			},
			pageInfo: {
				totalNum: 0,
				pageNum: 1
			}
		}
	},
	mounted() {
		this.init();
	},
	methods: {
		async init(){
      const result = await applicantApi.getApplicant({pageNum: this.pageInfo.pageNum, ...this.searchForm});
      if(result.code === 500){
        this.$alert('데이터 송신 중 오류가 발생했습니다.', '', 'error');
        return
      }
      this.pageInfo.totalNum = result.totalNum;
      this.applicantList = result.result;
    },
    changePage(pageNum){
      this.pageInfo.pageNum = pageNum;
      this.init();
    },
    searchData(data){
      this.pageInfo.pageNum = 1;
      this.searchForm = data;
      this.init();
    }
	},
};
</script>
