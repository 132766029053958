<template>
  <div id="content" class="cloud">
      <!-- cont_body// -->
      <section class="cont_body">

          <!-- sub_banner// -->
          <div class="sub_banner">
              <div class="inner">
                  <div class="txt_area">
                      <p class="small_txt">Better Late Than Never!</p>
                      <p class="main_txt">국내외 기업 전반의 비즈니스 중심에 클라우드가 있습니다.</p>
                  </div>
                  <div class="bnnr_img">
                      <img src="@/assets/img/sub/cloud/bannr_bg.png" alt="클라우드 서비스">
                  </div>
              </div>
          </div>
          <!-- //sub_banner -->
          <h2 class="h2_sub inner">
              <span class="gray_txt">고객 만족과 서비스 품질 혁신을 위한 최적의 디지털 환경을 구축하고 운영해보세요.</span>
              디지털 혁신의 클라우드
          </h2>
          <!-- work_prc// -->
          <div class="work_prc">
              <div class="inner">
                  <h3>
                      <strong>Work Process</strong>
                      <span>단 하나의 과정도 전문적인 노하우와 방법론을 통해 차원이 다른 서비스를 제공합니다.</span>
                  </h3>
                  <div class="work_stepwrap">
                      <ol>
                          <li>
                              <div class="icon_circle"><img src="@/assets/img/sub/cloud/workprc_cloud_01.png" alt="클라이언트 환경분석"></div>
                              <div class="work_desc">
                                  <strong>
                                      <span class="txt_step col_blu">STEP 01</span>
                                      환경 분석
                                  </strong>
                                  <p>
                                      서비스 환경 및<br>
                                      요구사항 분석
                                  </p>
                              </div>
                          </li>
                          <li>
                              <div class="icon_circle"><img src="@/assets/img/sub/cloud/workprc_cloud_02.png" alt="클라우드 모델선택"></div>
                              <div class="work_desc">
                                  <strong>
                                      <span class="txt_step col_blu">STEP 02</span>
                                      클라우드 모델링
                                  </strong>
                                  <p>
                                      클라우드 모델 선택<br>
                                      및 어플리케이션 재설계
                                  </p>
                              </div>
                          </li>
                          <li>
                              <div class="icon_circle"><img src="@/assets/img/sub/cloud/workprc_cloud_03.png" alt="인프라구현 및 적용"></div>
                              <div class="work_desc">
                                  <strong>
                                      <span class="txt_step col_blu">STEP 03</span>
                                      클라우드 이전
                                  </strong>
                                  <p>인프라 구현 및 적용 </p>
                              </div>
                          </li>
                          <li>
                              <div class="icon_circle"><img src="@/assets/img/sub/cloud/workprc_cloud_04.png" alt="구축 및 개발"></div>
                              <div class="work_desc">
                                  <strong>
                                      <span class="txt_step col_blu">STEP 04</span>
                                      구축 & 개발
                                  </strong>
                                  <p>
                                      신규 클라우드 기반<br>
                                      어플리케이션 최적화
                                  </p>
                              </div>
                          </li>
                          <li>
                              <div class="icon_circle"><img src="@/assets/img/sub/cloud/workprc_cloud_05.png" alt="모니터링 관리"></div>
                              <div class="work_desc">
                                  <strong>
                                      <span class="txt_step col_blu">STEP 05</span>
                                      모니터링 관리
                                  </strong>
                                  <p>
                                      지속적 모니터링을<br>
                                      통한 안정화
                                  </p>
                              </div>
                          </li>

                      </ol>
                  </div>
              </div>
           </div>
           <!-- //work_prc -->

            <!-- service_area// -->
            <ServiceInfo />
            <!-- service_area// -->

            <!-- gray_box// -->
            <div class="gray_box">
              <div class="inner">
                  <h3>
                      엔더스의 클라우드 전문가가 <br>효율적인 클라우드 기반 비즈니스를 지원해드립니다.
                  </h3>
                  <div class="support_list">
                      <dl>
                          <dt>유연한 확장</dt>
                          <dd>실제 국내 외 기업들이 애용하는<br>
                              클라우드 서비스의 유연한 확장
                          </dd>
                      </dl>
                      <dl>
                          <dt>효율적 운영</dt>
                          <dd>운영 통합 관리 자동화를 통한<br>
                              효율성 극대화
                          </dd>
                      </dl>
                      <dl>
                          <dt>우수한 안전성</dt>
                          <dd>실제 국내 외 기업들이 애용하는<br>
                              검증된 보안, 인프라 안정성 확보
                          </dd>
                      </dl>
                  </div>
              </div>
            </div>
            <!-- //gray_box -->
          <!-- sub_common// -->
            <PortfolioBanner />
          <!-- //sub_common -->
          <!-- 문의상담영역// -->
            <ConsultBanner />

          <!-- //문의상담영역 -->
      </section>
      <!-- //cont_body -->
  </div>
</template>
<script>
import ConsultBanner from '@/components/ConsultBanner.vue';
import PortfolioBanner from '@/components/PortfolioBanner.vue';
import ServiceInfo from './cloud-compo/ServiceInfo.vue';

export default {
  // name은 파일 이름을 쓰시면 됩니다. ex) Portfolio.vue => Portfolio
  name: 'Cloud',
  // components 위에서 임포트한 컴포넌트를 여기에 등록하고 위에 HTML영역에 태그로 사용가능합니다.
  components: {
    ConsultBanner,
    PortfolioBanner,
    ServiceInfo,
  },
};
</script>
