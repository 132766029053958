<template>
  <div id="content">
		<section class="cont_head">
			<AdminPageTitle
			title="연혁관리"
			/>
		</section>

		<section class="cont_body">
			<fieldset>
				<legend>조건 및 추출조건 선택</legend>
				<HistoryList :list="historyList" :pageInfo="pageInfo" />
				<Pagination :pageInfo="pageInfo" @change-page="changePage"/>
			</fieldset>
		</section>
	</div>
</template>

<script>
import AdminPageTitle from '@/components/admin/AdminPageTitle.vue';
import Pagination from '@/components/admin/Pagination.vue';
import historyApi from '@/apis/history';
import HistoryList from './compo/HistoryList.vue';

export default {
  // name은 파일 이름을 쓰시면 됩니다. ex) Portfolio.vue => Portfolio
  name: 'History',
  // components 위에서 임포트한 컴포넌트를 여기에 등록하고 위에 HTML영역에 태그로 사용가능합니다.
  components: {
    AdminPageTitle,
		HistoryList,
		Pagination,
  },
  data(){
    return{
      historyList: [],
      pageInfo:{
        totalNum: 0,
        pageNum: 1
      },
    }
  },
  mounted(){
    this.init();
  },
  methods:{
    async init(){
      const result = await historyApi.getHistory({pageNum: this.pageInfo.pageNum});
      if(result.code === 500){
        this.$alert('데이터 송신 중 오류가 발생했습니다.', '', 'error');
        return
      }
      this.pageInfo.totalNum = result.totalNum;
      this.historyList = result.result;
    },
    changePage(pageNum){
      this.pageInfo.pageNum = pageNum;
      this.init();
    },
  }
};
</script>
