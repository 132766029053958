<template>
  <div class="service_area srvc_solution">
    <div class="inner">
      <h3 class="h3_ttl">UMS 솔루션주요기능</h3>
      <!-- service_list// -->
      <div class="service_list type_pc">
        <SeviceInfoItem v-for="(item, idx) in serviceItem" :key="idx" :pcItem="item"/>
      </div>
      <!-- //type_pc -->

      <!-- service_list// -->
      <div class="service_list type_mb">
        <Swiper :options="swiperOptions">
          <SwiperSlide class="swiper-slide" v-for="(item, idx) in serviceItem" :key="idx" >
            <SeviceInfoItem :pcItem="item"/>
          </SwiperSlide>
          <div class="swiper-pagination mb_srv_pager swiper-pagination-bullets" slot="pagination"></div>
        </Swiper>
      </div>
      <!-- //type_mb-->
      <!-- //service_list -->
    </div>
  </div>
</template>

<script>
import SeviceInfoItem from './SeviceInfoItem.vue';

export default {
  name: 'ServiceInfoUMS',
  components: {
    SeviceInfoItem,
  },
  data() {
    return {
      swiperOptions: {
        spaceBetween: 30,
        pagination: {
          el: '.swiper-pagination',
        },
      },
      serviceItem: [
        {
          img: require('@/assets/img/sub/axon/ums_01.png'),
          title: '운영비용 절감 특효!',
          content:
            '대고객 메시징 관리비용 절감',
          f1Title: '예산 관리',
          f1Content: '채널별 조건에 따라 발송량을 측정 및 예측하여 예상 비용의 효율적인 관리',
          f2Title: '누수 예방',
          f2Content: '누수 메시지 방지와 순차 발송 기능을 통해 낭비되는 비용 감소',
          f3Title: '',
          f3Content: '',
        },
        {
          img: require('@/assets/img/sub/axon/ums_02.png'),
          title: '업무효율 극대!',
          content:
            '통계 기반 스마트 업무 지원',
          f1Title: '발송 현황',
          f1Content: '채널별로 발송 성공/실패를 구체적인 수치로 통계 정보 제공',
          f2Title: '통계 그래프',
          f2Content: '알림톡 앱/웹 푸시부터 수신거부, 클릭 비율 등 맞춤형 통계 그래프 제공',
          f3Title: '모니터링',
          f3Content: '채널별 모듈에 관한 상태 모니터링 제공',
        },
        {
          img: require('@/assets/img/sub/axon/ums_03.png'),
          title: '명확한 데이터 성과 측정',
          content:
            '기간, 조건, 채널별 발송 현황 확인',
          f1Title: '통계',
          f1Content: 'OS, 발송 응답 시간별, 세부 에러, 고객별 등 조건에 따른 통계 조회 및 엑셀 다운로드와 출력 지원',
          f2Title: '발송 현황',
          f2Content: '조건 검색에 맞는 현황부터 예약 발송 현황, 발송 성공/실패량 일반 발송 등 다양한 맞춤 현황 조회 확인',
          f3Title: '',
          f3Content: '',
        },
        {
          img: require('@/assets/img/sub/axon/ums_04.png'),
          title: '맞춤형 타겟팅 관리지원',
          content:
            '고객 정책에 의한 채널/발송 유형별 설정 지원',
          f1Title: '서비스별 관리',
          f1Content: 'Web, APP, 카카오 알림톡, SMS별로 템플릿 관리',
          f2Title: '맞춤 Tool',
          f2Content: '편리하고 정교한 고객 세분화 Tool 제공',
          f3Title: '맞춤 타겟팅',
          f3Content: '조직별 검수 및 승인 기능으로, 메시지별 분류 발송',
        },
      ],
    };
  },
};
</script>
