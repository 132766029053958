<template>
  <div class="faq_box">
    <h3 class="h3_title">FAQ</h3>

    <!-- faq_acco_wrap// -->
    <div class="acco_wrap">
      <FaqInfoItem
        :faqItem="item"
        v-for="item in faqItem"
        :key="item.id"
        @open-faq="openFaq"
      />
    </div>
    <!-- //faq_acco_wrap -->
    <p>※ 지원관련 문의가 있으신 경우, support@enders.co.kr로 메일 부탁드립니다.</p>
  </div>
</template>

<script>
import FaqInfoItem from './FaqInfoItem.vue';

export default {
  name: 'FaqInfo',
  components: { FaqInfoItem },
  data() {
    return {
      faqItem: [
        {
          id: 1,
          title: '지원하려면 어떻게 해야 하나요?',
          answer: '모집분야 페이지 또는 채용 사이트 공고를 통해 지원이 가능합니다.',
          active: false,
        },
        {
          id: 2,
          title: '서류전형 결과는 언제 받아볼 수 있을까요?',
          answer:
            '엔더스는 지원자의 역량을 세심하게 검토하고자 최대 2주 정도 소요됩니다. 상시모집을 지원하신 경우, 내부 포지션에 따라 지원서가 검토 되며 별도의 기간이 정해져 있지 않는 점 지원에 참고 부탁드립니다.',
          active: false,
        },
        {
          id: 3,
          title: '자기소개서 외 이력서와 포트폴리오는 언제 어떻게 제출하면 되나요?',
          answer:
            '자기소개서와 첨부자료는 자유 양식이며 크루 지원 등록 시, 함께 첨부해 주셔야 합니다. 누락된 경우 support@enders.co.kr로 메일 문의 부탁드립니다.',
          active: false,
        },
        {
          id: 4,
          title: '업무 경험이 있으나 공고 기준에 미달됩니다. 지원 할 수 있을까요?',
          answer:
            '엔더스는 지원자의 가능성과 다양한 능력에 관심이 큽니다. 서류심사와 면접을 통해 역량을 보여주실 수 있는 채용의 기회는 언제든지 열려 있습니다.',
          active: false,
        },
        {
          id: 5,
          title: '중복지원이 가능한가요?',
          answer: '엔더스는 전문역량을 중시하여 중복지원이 불가합니다.',
          active: false,
        },
      ],
    };
  },
  methods: {
    openFaq(id) {
      this.faqItem.forEach((n) => {
        if (n.id === id) {
          // eslint-disable-next-line no-param-reassign
          n.active = true;
        } else {
          // eslint-disable-next-line no-param-reassign
          n.active = false;
        }
      });
    },
  },

};
</script>

<style></style>
